
import React, { Component } from "react";
import { Route, Redirect, } from "react-router-dom";
import CompanyIndex from './../components/company-index'
import companyBaseInfo from './../components/account/company-base-info'

class FrontendAuth extends Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        const { routerConfig, location } = this.props;
        const { pathname } = location;
        const isLogin = localStorage.getItem("token");
        const userStatus = localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')).status===3||JSON.parse(localStorage.getItem('userInfo')).status===2?true:false:false;
        // 如果该路由不用进行权限校验，登录状态下登陆页除外
        // 因为登陆后，无法跳转到登陆页
        const targetRouterConfig = routerConfig.find(
            (items) => items.path === pathname
        );

        // 这部分代码，是为了在非登陆状态下，访问不需要权限校验的路由
        if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
            console.log('无需验证')
            const { component } = targetRouterConfig;
            return <Route exact path={pathname} component={component} />;
        }
        if (isLogin) {
            // if (userStatus === 1) {
            //     //审核中，跳转到审核页面
            //     console.log(1)
            //     return <Route exact path='/companyBaseInfo' component={companyBaseInfo} />;
            // } else {
                    //路由合法
                console.log(2)
                    if (targetRouterConfig) {
                        if (targetRouterConfig.layout) {
                            return <CompanyIndex routes={targetRouterConfig}  {...this.props}>
                                <Route
                                    exact
                                    path={targetRouterConfig.path}
                                    render={props => {
                                        return <targetRouterConfig.component {...props} routes={targetRouterConfig}></targetRouterConfig.component>
                                    }}
                                />
                            </CompanyIndex>
                        } else {
                            // console.log(2)
                            return (
                                <Route path={pathname} component={targetRouterConfig.component} title={targetRouterConfig.title} />
                            );
                        }
                    } else {
                        return <Redirect to="/404" />;
                    }
            // }
        } else {
            // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
            if (targetRouterConfig && targetRouterConfig.auth) {
                return <Redirect to="/login" />;
            } else {
                // 非登陆状态下，路由不合法时，重定向至 404
                return <Redirect to="/404" />;
            }
        }
    }
}
export default FrontendAuth;