import React, { Component } from 'react';

class error extends Component {
    state = {  }
    render() { 
        return ( <div>
            <h1>404</h1>
        </div> );
    }
}
 
export default error;