import React, { Component } from 'react'
import { Avatar ,Divider, Row,message,Col} from 'antd';
import './talent-collection-card.less'
import { Link, } from "react-router-dom";
import common from '../../public/base'
import http from '../../http'

export default class talentCollectionItem extends Component {
    constructor(props) {
        super(props)
    }
    //取消收藏
    cancleStar=()=>{
        http.talenCancletStar(this.props.resumeDetail.id).then(res=>{
            if(res.result===1){
                this.props.fresh()
                message.success('取消收藏成功')
            }
        })
    }
    render() {
        const { resumeDetail } = this.props
        const {expect_position,experience_work} = resumeDetail;

        return (
            <div>
                <div className="tc_container">
                    <div className="center">
                        <Row className="containerHead">
                            <Col span='9' className="imformation">
                                <div>
                                    <div className="avatar">
                                        <Avatar size={40} src={resumeDetail.head_img}/>
                                    </div>
                                    <div className="name">
                                        <Link  className="route"
                                                to={
                                                    {
                                                        pathname:`/resume`,
                                                        state:{id:resumeDetail.id}
                                                    }
                                                }>
                                            {resumeDetail.name}
                                        </Link>
                                    </div>
                                </div>
                                <div className="basicImformationBox">
                                    <div className="basicImformation1">
                                        {resumeDetail.sex === 0 ? '男' : '女'}<Divider type="vertical" />
                                        {common.filterBirth(resumeDetail.birthday)}<Divider type="vertical" />
                                        {common.filterBirth(resumeDetail.start_work)}年工作经验<Divider type="vertical" />
                                        {resumeDetail.education.name}<Divider type="vertical" />
                                        {["离职-随时到岗", "在职-暂不考虑", "在职-考虑机会", "离职-月内到岗"][resumeDetail.job_status-1]}
                                    </div>
                                    {expect_position.length>0?
                                    <div className="basicImformation2">
                                        期望：{expect_position[0].city}<Divider type="vertical" />
                                        {expect_position[0].name}<Divider type="vertical" />
                                        ¥{expect_position[0].min_pay}-¥{expect_position[0].max_pay}
                                    </div>
                                    :
                                    <div className="basicImformation2">
                                        期望：暂无
                                    </div>
                                    }
                                </div>
                            </Col>
                            <Col span='9' className="experience">
                                <div className="experienceBox">
                                    <div className="experienceIcon" >
                                        <img className="icon" src={require('../../assets/talentCollection-icon.png').default} alt="" />
                                    </div>
                                    <div className="experienceTextBox">
                                        <div className="first">
                                        {resumeDetail.experience_work.map((e, index) => {
                                            if(index<3){
                                                return (
                                                    <div key={index} className="exprinenceItem">
                                                        <div className="experienceTime">
                                                            {common.dateFormat(e.start_time)} 
                                                            - 
                                                            {common.dateFormat(e.end_time)}
                                                        </div>
                                                        <div className="experienceText">
                                                            {e.name}&nbsp;&nbsp;&nbsp; {e.position}
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            })    
                                        }  
                                        </div>
                                    </div>
                                </div>
                                <div className="experienceBox">
                                    <div className="experienceIcon" >
                                        <img className="icon" src={require('../../assets/talentCollection-icon.png').default} alt="" />
                                    </div>
                                    <div className="experienceTextBox">
                                        {resumeDetail.experience_edu.map((e, index) => {
                                                if(index<1){
                                                    return (
                                                        <div key={index} className="exprinenceItem">
                                                            <div className="experienceTime">
                                                                {common.dateFormat(e.start_time)} 
                                                                - 
                                                                {common.dateFormat(e.end_time)}
                                                            </div>
                                                            <div className="experienceText">
                                                                {e.education_name.replace("|education","")}&nbsp;&nbsp;&nbsp; {e.profession}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col span='6' className="buttonBox">
                                <div >
                                    <Link to={
                                        {
                                            pathname:`/resume`,
                                            state:{id:resumeDetail.id}
                                        }
                                    }>
                                    <button className="button1" onClick={()=>{this.props.history.push(this.props.history.push({ pathname: '/resume', state: { id: resumeDetail.id } })
                                            )}}>
                                                
                                            查看人才
                                    </button>
                                    </Link>
                                </div>
                                <div>
                                    <button className="button2" onClick={this.cancleStar}>取消收藏</button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="divider">
                            <Divider dashed /> 
                        </Row>
                        <Row className="time">
                            收藏时间：{resumeDetail.post_time!=null ? common.dateFormat(resumeDetail.post_time):'暂无'}
                            <Divider type="vertical" />
                            投递职位：{resumeDetail.position_name!=null? resumeDetail.position_name:'暂无'}
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}
