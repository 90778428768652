import React, { Component } from 'react'
import { Row, Col, Avatar, Button,Menu,Dropdown,Divider,Alert, message } from "antd";
import { DownOutlined} from '@ant-design/icons';
import { Link, } from "react-router-dom";
import './talent-manage-card.less'
import common from '../../public/base'
import http from '../../http'



export default class talentManageCard extends Component {
    constructor(props) {
        super(props)
    };
    //改变职位状态
    handleMenuClick(e,id) {
        let pid=this.props.resumeDetail.position_id
        let s=1
        if(e.key==1){
            s=1
        }else if(e.key==2){
            s=2
        }else if(e.key==3){
            s=3
        }else if(e.key==4){
            s=4
        }
        console.log(s)
        http.changeStatus(id,{position_id:pid,status:s}).then(res=>{
            console.log(res)
            if(res.result==1){
               message.success('操作成功')
               this.props.fresh()
            }
        })
    }
    //判定职位处理状态
    menu(mark,id){
        let type
        const toDealMenu = (
            <Menu onClick={(e)=>{this.handleMenuClick(e,id)}}>
              <Menu.Item key="2" >
                沟通中
              </Menu.Item>
              <Menu.Item key="3">
                已沟通
              </Menu.Item>
              <Menu.Item key="4">
                不合适
              </Menu.Item>
            </Menu>
        );
        const dealingMenu = (
            <Menu onClick={(e)=>{this.handleMenuClick(e,id)}}>
              <Menu.Item key="3">
                已沟通
              </Menu.Item>
              <Menu.Item key="4">
                不合适
              </Menu.Item>
            </Menu>
        );

        switch(mark){
            case 1:
                type=toDealMenu;
                break;
            case 2:
                type=dealingMenu;
                break;
            default :
                type=toDealMenu
                break;
        }

        return type;
    }

    render() {
        const { resumeDetail,mark} = this.props
        const {expect_position,experience_work} = resumeDetail;
        let menu=this.menu(mark,this.props.resumeDetail.id);

        return (
            <div>
                <div className="tm_container">
                    <div className="center">
                        <Row className="containerHead">
                            <Col span='9' className="imformation">
                                <div>
                                    <div className="avatar" >
                                        <Avatar size={40} src={resumeDetail.head_img}/>
                                    </div>
                                    <div className="name">
                                        <Link className="route"
                                            to={
                                                {
                                                    pathname:`/resume`,
                                                    state:{id:resumeDetail.id}
                                                }
                                            }>
                                            {resumeDetail.name}
                                        </Link>
                                    </div>
                                </div>
                                <div className="basicImformationBox">
                                    <div className="basicImformation1">
                                        {resumeDetail.sex === 0 ? '男' : '女'}<Divider type="vertical" />
                                        {common.filterBirth(resumeDetail.birthday)}<Divider type="vertical" />
                                        {common.filterBirth(resumeDetail.start_work)}年工作经验<Divider type="vertical" /> 
                                        {resumeDetail.education.name}<Divider type="vertical" />
                                        {["离职-随时到岗", "在职-暂不考虑", "在职-考虑机会", "离职-月内到岗"][resumeDetail.job_status-1]}
                                    </div>
                                    {expect_position.length>0?
                                    <div className="basicImformation2">
                                        期望：{expect_position[0].city}<Divider type="vertical" />
                                        {expect_position[0].name}<Divider type="vertical" />
                                        ¥{expect_position[0].min_pay}-¥{expect_position[0].max_pay}
                                    </div>
                                    :
                                    <div className="basicImformation2">
                                        期望：暂无
                                    </div>
                                    }
                                </div>
                            </Col>
                            <Col span='9' className="experience">
                                <div className="experienceBox">
                                    <div className="experienceIcon" >
                                        <img className="icon" src={require('../../assets/talentCollection-icon.png').default} alt="" />
                                    </div>
                                    <div className="experienceTextBox">
                                        <div className="first">
                                            {resumeDetail.experience_work.map((e, index) => {
                                                if(index<3){
                                                    return (
                                                        <div key={index} className="exprinenceItem">
                                                            <span className="experienceTime">
                                                                {common.dateFormat(e.start_time)} 
                                                                - 
                                                                {common.dateFormat(e.end_time)}
                                                            </span>
                                                            <span className="experienceText">
                                                                {e.name}&nbsp;&nbsp;&nbsp; {e.position}
                                                            </span>
                                                        </div>
                                                        )
                                                    }
                                                })}  
                                        </div>
                                    </div>
                                </div>
                                <div className="experienceBox">
                                    <div className="experienceIcon" >
                                        <img className="icon" src={require('../../assets/talentCollection-icon.png').default} alt="" />
                                    </div>
                                    <div className="experienceTextBox">
                                            {resumeDetail.experience_edu.map((e, index) => {
                                                if(index<1){
                                                    return (
                                                        <div key={index} className="exprinenceItem">
                                                            <div className="experienceTime">
                                                                {common.dateFormat(e.start_time)} 
                                                                - 
                                                                {common.dateFormat(e.end_time)}
                                                            </div>
                                                            <div className="experienceText">
                                                                {e.education_name.replace("|education","")}&nbsp;&nbsp;&nbsp; {e.profession}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                    </div>
                                </div>
                            </Col>
                            <Col span='6' className="buttonBox">
                                <div>
                                    <button className="button1" onClick={()=>{this.props.jumpJobDetaiil()}} >查看人才</button>
                                </div>
                                <div>
                                    {   resumeDetail.status==1||resumeDetail.status==2?
                                        <Dropdown overlay={menu} key={resumeDetail.id}> 
                                            <Button className="button3">
                                                操作 <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                        :
                                        resumeDetail.status==3?<div className="message">已沟通</div>:<div className="message">不合适</div>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className="divider">
                            <Divider dashed /> 
                        </Row>
                        <Row className="time">
                            收藏时间：{resumeDetail.post_time!=null ? common.dateFormat(resumeDetail.post_time):'暂无'}
                            <Divider type="vertical" />
                            投递职位：{resumeDetail.position_name!=null? resumeDetail.position_name:'暂无'}
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}
