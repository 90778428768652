import React, { Component, useState, useEffect } from 'react';
import { Radio, Menu, Form, Input, Button, Select, Tag, Modal, message, Upload, Tabs, PageHeader, Table, Col, Row, Space, notification } from 'antd';
import {
    ArrowLeftOutlined, LoadingOutlined, PlusOutlined
} from '@ant-design/icons';
import http from '../../http';
import common from './../../public/base';
import './account-index.less'
const { Option } = Select;
const { TabPane } = Tabs;


const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};


const getCode = function (phone) {
    console.log(phone)
    if (phone) {
        http.getCode({ send: phone }).then(res => {
            console.log(res)
            if (res.result === 1) {
                message.success('发送成功')
            }
        })
    } else {
        message.warn('请输入手机号');
    }
}

const validateMessages = {
    required: '${label}是必填选项!',
    types: {
        email: '请输入${label}!',
    },
};
const { SubMenu } = Menu;

//弹窗选择职位福利
class ShowindustryWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectIndudtry: [],
            allIndustryList: []
        }
    }
    // handleSendPMsg = ()=>{
    //   this.props.getMsg(this.state.val)
    // }
    componentDidMount() {
        console.log(this.props, "+++++++++++++++++")
        let data = [];
        this.props.allIndustryList.map((e) => {
            e.select = false;
            if (this.props.selectIndudtry) {
                this.props.selectIndudtry.map((item) => {
                    if (e.name === item.name) {
                        e.select = true;
                    }
                })
            }
            data.push(e)
        })
        this.setState({
            allIndustryList: data,
            selectIndudtry: this.props.selectWelfare
        })
    }

    handleSendPMsg(index) {
        // console.log(this.state.allWelfareList)
        let data = this.state.allIndustryList;
        data[index].select = !data[index].select
        let selectData = []
        data.forEach(element => {
            if (element.select) {
                selectData.push(element)
            }
        });
        this.setState({
            allIndustryList: data,
            selectIndudtry: selectData
        })
        this.props.onSelectIndudtry(selectData)
    }
    render() {
        const selectStyle = { 'marginRight': '8px', 'marginBottom': '8px', 'borderColor': '#3188F3', 'color': '#3188F3' };
        return this.props.allIndustryList.map((e, index) => {
            return <li onClick={this.handleSendPMsg.bind(this, index)} className='welfare-li' style={e.select ? selectStyle : { 'marginRight': '8px', 'marginBottom': '8px' }} key={e.id}>{e.name}</li>
        })
    }
}

//企业信息表单
const FormModule = (prop) => {
    const [form] = Form.useForm();
    const formRef = React.createRef()
    const { transferData } = prop
    const { companyInfo, companyScales, companyTypes, } = transferData
    const [companyIndustry, setCompanyIndustry] = useState([]);
    const [selectIndustry, setSelectIndustry] = useState([]);
    let provinceInfo = JSON.parse(localStorage.getItem('map'))
    const [cityInfo, setCityInfo] = useState([]);
    const [regionInfo, setRegionInfo] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingLogo, setLoadingLogo] = useState(false)
    const [fileList, setFileList] = useState([])
    const [licenseImg, setLicenseImg] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    const [disabled, setDisabled] = useState(true) //不可编辑
    const [isModalVisible, setIsModalVisible] = useState(false)
    let industryList = [];

    useEffect(() => {

        form.setFieldsValue({
            name: companyInfo.name,
            description: companyInfo.description,
            province: companyInfo.address ? companyInfo.address.province_id : null,
            city: companyInfo.address ? companyInfo.address.city_id : null,
            region: companyInfo.address ? companyInfo.address.region_id : null,
            address_str: companyInfo.address ? companyInfo.address.address : '',
            mail: companyInfo.mail,
            url: companyInfo.url??'',
            logo: companyInfo.logo,
            license: companyInfo.license,
            scale: companyInfo.scale.id,
            types: companyInfo.types
        });
        setCityInfo(transferData.cityInfo)
        setRegionInfo(transferData.regionInfo)
        setLicenseImg(companyInfo.license)
        setLogoUrl(companyInfo.logo)
        setSelectIndustry(companyInfo.industry)
        setCompanyIndustry(transferData.companyIndustry)

    }, [transferData])


    const UploadButton = (prop) => {
        return (<div>
            {prop.loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>);
    };

    const handleProvinceChange = value => {
        console.log(value)
        http.getMapInfo({ id: value }).then(res => {
            setCityInfo(res.data.sub)
        })
    }
    const handleCityChange = value => {
        console.log(value)
        http.getMapInfo({ id: value }).then(res => {
            setRegionInfo(res.data.sub)
        })
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('请上传jpeg、png格式图片！');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小不超过2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChangebBusiness = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
        }
        if (info.file.status === 'done') {
            common.getBase64(info.file.originFileObj, imageUrl => {
                setLoading(false)
                setLicenseImg(info.file.response.data)
            }
            );
        }
        setFileList([...info.fileList]);
    };


    const handleChangeLogo = info => {
        if (info.file.status === 'uploading') {
            setLoadingLogo(true)
        }
        if (info.file.status === 'done') {
            common.getBase64(info.file.originFileObj, (imageUrl) => {
                setLoadingLogo(false)
                setLogoUrl(info.file.response.data)
            }
            );
        }
        setFileList([...info.fileList])
    }

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    //添加行业
    const editIndustry = () => {
        setIsModalVisible(true)
    }
    //选择行业
    const onSelectIndudtry = (res) => {
        console.log(res)
        industryList = res;
    }
    //确认所选行业
    const handleOk = () => {
        setSelectIndustry(industryList)
        setIsModalVisible(false)
    }
    //删除行业
    const closeTag = e => {
        console.log(disabled)
        if (disabled) {
            message.warn('暂无权限，请点击编辑按钮')
            return;
        } else {
            console.log(e)
            const tags = selectIndustry.filter(tag => tag !== e);
            setSelectIndustry(tags)
        }
    }

    //编辑权限打开
    const onEdit = () => {
        if (disabled) {
            setDisabled(false)
        }
    }

    const onFinish = value => {
        if (disabled) return;
        if (value.province == null || value.province == undefined) {
            message.error('公司地址是必填项！');
            return;
        }
        if (selectIndustry.length == 0) {
            message.error('所属行业是必填项！');
            return;
        }
        let industry = []
        selectIndustry.map(e => {
            industry.push(e.id)
        })
        let data = value
        data.logo = logoUrl;
        data.license = licenseImg;
        data.industry = industry.join(',')
        http.reviseCompanyInfo(data).then((res) => {
            if (res.result === 1) {
                http.getCompanyInfo().then(info => {
                    localStorage.setItem('userInfo', JSON.stringify(info.data))
                })
                message.success('修改成功！')
                setDisabled(true)
            }
        })
        console.log(data)
    }


    const selectStyle = { 'marginRight': '8px', 'marginBottom': '8px', 'borderColor': '#3188F3', 'color': '#3188F3' };
    return <div className="form-box">
        <Form form={form} onFinish={onFinish} ref={formRef} className='form' {...layout} validateMessages={validateMessages}>
            <Form.Item name='name' label="企业名称" rules={[{ required: true }]}>
                <Input disabled={disabled} placeholder='请填写企业名称' />
            </Form.Item>
            <Form.Item name='description' label='企业简介' rules={[{ required: true }]}>
                <Input.TextArea disabled={disabled} autoSize={{ minRows: 6 }} placeholder='请填写企业简介' />
            </Form.Item>
            <Form.Item className='address' label="公司地址" >
                <Form.Item name='province' style={{ display: 'inline-block', width: 'calc(33.3% - 24px)' }} rules={[{ required: true, message: '省是必填选项！' }]}>
                    <Select disabled={disabled} placeholder='请选择省' onChange={handleProvinceChange}>
                        {provinceInfo.map(province => (
                            <Option key={province.id} value={province.id}>{province.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <span style={{ display: 'inline-block', width: '24px' }}></span>
                <Form.Item name='city' style={{ display: 'inline-block', width: 'calc(33.3% - 24px)' }} rules={[{ required: true, message: '市是必填选项！' }]}>
                    <Select disabled={disabled} placeholder='请选择市' onChange={handleCityChange}>
                        {cityInfo.map(e => (
                            <Option key={e.id} value={e.id}>{e.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <span style={{ display: 'inline-block', width: '24px' }}></span>
                <Form.Item name='region' style={{ display: 'inline-block', width: 'calc(33.3%)' }} rules={[{ required: true, message: '区是必填选项！' }]}>
                    <Select disabled={disabled} placeholder='请选择区'>
                        {regionInfo.map(e => (
                            <Option key={e.id} value={e.id}>{e.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form.Item>
            <Form.Item name='address_str' label="详细地址" rules={[{ required: true }]}>
                <Input disabled={disabled} placeholder='请填写详细地址' />
            </Form.Item>
            <Form.Item name='mail' label="常用邮箱" rules={[{ required: true, type: 'email', }]}>
                <Input disabled={disabled} placeholder='请输入接收简历的邮箱' />
            </Form.Item>
            <Form.Item
                name="scale"
                label="公司规模"
                rules={[{ required: true, message: '请选择公司规模' }]}>
                <Select disabled={disabled} placeholder="请选择">
                    {companyScales ? companyScales.map((e, index) => {
                        return <Option key={e.id} value={e.id}>{e.name}</Option>
                    }) : null}
                </Select>
            </Form.Item>
            <Form.Item
                name="types"
                label="公司类型"
                rules={[{ required: true, message: '请选择公司类型' }]}>
                <Select disabled={disabled} placeholder="请选择">
                    {companyTypes ? companyTypes.map((e, index) => {
                        return <Option key={e.id} value={e.id}>{e.name}</Option>
                    }) : null}
                </Select>
            </Form.Item>

            <Form.Item className='job-type' label="所属行业">
                {selectIndustry ? selectIndustry.map((e, index) => {
                    return <Tag className={disabled ? 'disabled-tag' : null} key={e} onClose={() => closeTag(e)} closable={!disabled} key={e.id}>{e.name}</Tag>
                }) : null}
                {disabled ? null : <Button type="dashed" onClick={editIndustry}>+ 添加行业</Button>}
            </Form.Item>
            <Form.Item name='url' label='公司网址'>
                <Input disabled={disabled} placeholder='请输入公司网址' />
            </Form.Item>

            <Form.Item name='license' label="营业执照" rules={[{ required: true }]}>
                <Upload
                    disabled={disabled}
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={http.baseUrl + "/common/file/upload/img"}
                    beforeUpload={beforeUpload}
                    onChange={handleChangebBusiness}
                    data={file => ({// file 是当前正在上传的图片
                        file_name: file.size + file.name.replace(/\s/g, '')
                    })}
                    fileList={fileList}
                    maxCount={1}
                    headers={{ 'id': 0, }}
                >
                    {licenseImg ? <img src={licenseImg} alt="avatar" style={{ width: '100%' }} /> : <UploadButton loading={loading} />}
                </Upload>
            </Form.Item>
            <Form.Item name='logo' label="公司logo" rules={[{ required: true }]} style={{ 'position': 'relative' }}>
                <Upload
                    disabled={disabled}
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={http.baseUrl + "/common/file/upload/img"}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeLogo}
                    data={file => ({// file 是当前正在上传的图片
                        file_name: file.size + file.name.replace(/\s/g, '')
                    })}
                    fileList={fileList}
                    maxCount={1}
                    onPreview={onPreview}
                    headers={{ 'id': 0, }}
                >
                    {logoUrl ? <img src={logoUrl} alt="avatar" style={{ width: '100%' }} /> : < UploadButton loading={loadingLogo} />}
                </Upload>
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                {disabled ? <Button type="primary" onClick={onEdit}>
                    编辑
                </Button> : null} {!disabled ? <Button type="primary" htmlType="submit">
                    保存
                </Button> : null}
            </Form.Item>
        </Form>

        <Modal width={700} className='welfare-box' cancelText='取消' okText='确定' title="职位福利" visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)}>
            {companyIndustry ? <ShowindustryWidget onSelectIndudtry={onSelectIndudtry} allIndustryList={companyIndustry} selectIndudtry={companyInfo.industry ? companyInfo.industry : []}></ShowindustryWidget>
                : null}
        </Modal>
    </div>
}

//添加子账号
const AddAccountForm = (props) => {
    const [form] = Form.useForm();

    const add = value => {
        http.addCompanyMember({
            params: { member: value.username },
            headersData: { send: value.username, code: value.code }
        }).then(res => {
            message.success('添加成功')
            props.hideModal()
        })
    }
    return <Form form={form} className="add-form" onFinish={add}>
        <Form.Item>
            <p style={{ 'color': '#828282', 'fontSize': '14px', 'marginBottom': '0' }}>请先注册指南车账号，再将其添加到子账号列表（已成为其他公司子账号的账号请先联系管理员退出旧公司，再加入新公司）</p>
        </Form.Item>
        <Form.Item name="username" rules={[{ required: true, len: 11, message: '请输入手机号' }]}>
            <Input placeholder="请输入手机号" />
        </Form.Item>
        <Form.Item name="code" rules={[{ required: true, len: 6, message: '请输入验证码' }]}>
            <Row className='code-input-row'>
                <Col span='18'><Input bordered={false} placeholder="请输入验证码" /></Col>
                <Col span='6'><div onClick={() => { getCode(form.getFieldValue().username) }} className="get-code">获取验证码</div></Col>
            </Row>
        </Form.Item>
        <Form.Item className='account-btn'>
            <Button style={{ 'marginRight': '8px' }} onClick={() => props.onCancel()}>取消</Button>
            <Button type="primary" htmlType="submit" className="code-login-form-button form-button">确认添加</Button>
        </Form.Item>
    </Form>
}

///切换主账号
const ChangeMasterAccountForm = (props) => {
    const [indexFlag, setIndexFlag] = useState(0);//第一步
    const [typeFlag, setTypeFlag] = useState(1);//更换账号方式 默认子账号中选择
    const [phone, setPhone] = useState();
    const [form] = Form.useForm();

    const next = value => {
        console.log(value)
        setIndexFlag(1);
        setTypeFlag(value.type);
    }

    const change = value => {
        let headersData = { code: value.code, send: value.master }
        let params = { master: value.master }
        if (typeFlag === 1) {
            //子账号方式
            headersData = { code: value.code, send: JSON.parse(localStorage.getItem('userInfo')).phone }
            params = { master: phone }
        }
        http.changeMaster({
            params: params,
            headersData: headersData
        }).then(res => {
            if (res.result === 1) {
                props.onConfirm()
            }
        })
    }
    return <div>
        {indexFlag === 0 ? <Form form={form} className="add-form" onFinish={next}>
            <Form.Item>
                <p style={{ 'marginBottom': '10px', 'color': '#333', 'fontSize': '14px' }}>请选择更换主账号的方式</p>
                <p style={{ 'color': '#828282', 'fontSize': '13px', 'marginBottom': '0' }}><span style={{ 'color': '#FF4D4F' }}>*</span>主账号换绑成功后，请使用新手机号登录人才网企业版，旧手机号将从当前企业移除。</p>
            </Form.Item>
            <Form.Item name="type" rules={[{ required: true, message: '请选择更换主账号方式' }]}>
                <Radio.Group  >
                    <Radio value={1}>从子账号中选择</Radio>
                    <Radio value={2}>更换为其他账号</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item className='account-btn'>
                <Button style={{ 'marginRight': '8px' }} onClick={props.onCancel}>取消</Button>
                <Button type="primary" htmlType="submit">下一步</Button>
            </Form.Item>
        </Form>
            : indexFlag === 1 ? typeFlag == 1 ? <Form form={form} className="add-form" onFinish={value => { setIndexFlag(2); setPhone(value.master) }}>
                <Form.Item>
                    <p style={{ 'color': '#828282', 'fontSize': '14px', 'marginBottom': '0' }}>请选择一个子账号变更为主账号</p>
                </Form.Item>
                <Form.Item name="master" rules={[{ required: true, len: 11, message: '请选择子账号' }]}>
                    <Select style={{ 'height': '40px' }} placeholder="请选择子账号" >
                        {
                            props.childAccount.map((e, index) => {
                                if (index > 0) {
                                    return <Option key={e.name} value={e.name}>{e.name}</Option>
                                }
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item className='account-btn'>
                    <Button style={{ 'marginRight': '8px' }} onClick={() => {
                        setIndexFlag(0)
                    }}>上一步</Button>
                    <Button type="primary" htmlType="submit">下一步</Button>
                </Form.Item>
            </Form>
                : <Form form={form} className="add-form" onFinish={change}>
                    <Form.Item>
                        <p style={{ 'color': '#828282', 'fontSize': '14px', 'marginBottom': '0' }}>新的主账号需要注册过指南车账号，且没有与其他公司绑定。</p>
                    </Form.Item>
                    <Form.Item name="master" rules={[{ required: true, len: 11, message: '请输入手机号' }]}>
                        <Input placeholder="请输入新的手机号" />
                    </Form.Item>
                    <Form.Item name="code" rules={[{ required: true, len: 6, message: '请输入验证码' }]}>
                        <Row className='code-input-row'>
                            <Col span='18'><Input bordered={false} placeholder="请输入验证码" /></Col>
                            <Col span='6'><div onClick={() => getCode(form.getFieldValue().master)} className="get-code">获取验证码</div></Col>
                        </Row>
                    </Form.Item>
                    <Form.Item className='account-btn'>
                        <Button style={{ 'marginRight': '8px' }} onClick={() => {
                        setIndexFlag(0)
                    }}>上一步</Button>
                        <Button type="primary" htmlType="submit">确认更换</Button>
                    </Form.Item>
                </Form> : <Form form={form} className="add-form" onFinish={change}>
                <Form.Item>
                    <p style={{ 'color': '#828282', 'fontSize': '14px', 'marginBottom': '0' }}>请验证当前手机号{JSON.parse(localStorage.getItem('userInfo')).phone}以更换主账号</p>
                </Form.Item>
                <Form.Item name="code" rules={[{ required: true, len: 6, message: '请输入验证码' }]}>
                    <Row className='code-input-row'>
                        <Col span='18'><Input bordered={false} placeholder="请输入验证码" /></Col>
                        <Col span='6'><div onClick={() => getCode(JSON.parse(localStorage.getItem('userInfo')).phone)} className="get-code">获取验证码</div></Col>
                    </Row>
                </Form.Item>
                <Form.Item className='account-btn'>
                    <Button style={{ 'marginRight': '8px' }} onClick={() => {
                        setIndexFlag(1)
                    }}>上一步</Button>
                    <Button type="primary" htmlType="submit">确认更换</Button>
                </Form.Item>
            </Form>}
    </div>
}

///子账号换绑手机号
const ChangePhoneForm = (props) => {
    const [form] = Form.useForm();
    const change = value => {
        http.changePhone({
            params: { username: value.username },
            headersData: { code: value.code, send: value.username }
        }).then(res => {
            if (res.result === 1) {
                props.onConfirm()
            }
        })
    }
    return <Form form={form} className="add-form" onFinish={change}>
        <Form.Item name="username" rules={[{ required: true, len: 11, message: '请输入手机号' }]}>
            <Input placeholder="请输入新的手机号" />
        </Form.Item>
        <Form.Item name="code" rules={[{ required: true, len: 6, message: '请输入验证码' }]}>
            <Row className='code-input-row'>
                <Col span='18'><Input bordered={false} placeholder="请输入验证码" /></Col>
                <Col span='6'><div onClick={() => getCode(form.getFieldValue().username)} className="get-code">获取验证码</div></Col>
            </Row>
        </Form.Item>
        <Form.Item className='account-btn'>
            <Button style={{ 'marginRight': '8px' }} onClick={props.onCancel}>取消</Button>
            <Button type="primary" htmlType="submit">确认更换</Button>
        </Form.Item>
    </Form>
}

class AccountIndex extends React.Component {
    state = {
        current: '1',
        companyInfo: JSON.parse(localStorage.getItem('userInfo')),
        provinceInfo: [],
        cityInfo: [],
        regionInfo: [],
        delFalg: false,
        delId: '',
        columns: [
            {
                title: '企业成员账号',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '成员权限',
                dataIndex: 'limited',
                key: 'limited',
                render: (e) => {
                    return (<Tag key={e} color={e ? 'geekblue' : 'green'}>{e ? '主号' : '子号'}</Tag>);
                }
            },
            {
                title: '操作',
                dataIndex: 'Action',
                key: 'Action',
                render: (e, record, index) => {
                    const changeAccount = () => {
                        this.setState({
                            showModal: true,
                            modalType: 'changeForm',
                            title: '更换主账号'
                        })
                    }
                    const delMember = () => {
                        this.setState({
                            delFalg: true,
                            delId: record.name
                        })
                    }
                    return (<Space size="middle">
                        <a onClick={record.limited ? changeAccount : delMember} style={record.limited ? null : { 'color': '#FF4D4F' }}>{record.limited ? '更换主账号' : '删除'}</a>
                    </Space>)
                }
            },
        ],
        dataSource: [],
        showModal: false,
        modalType: '',
        title: '',
        time: 10
    };


    delMember = () => {
        http.delCompanyMember({ member: this.state.delId }).then(res => {
            if (res.result === 1) {
                message.success('删除成功！')
                this.setState({
                    delFalg: false
                })
                this.getCompanyMenber()
            }
        })
    }
    handleClick = e => {
        this.setState({
            current: e.key,
        })
    }
    handleProvinceChange = value => {
        console.log(value)
        http.getMapInfo({ id: value }).then(res => {
            // setCityInfo(res.data.sub)
        })
    }
    //获取企业成员
    getCompanyMenber() {
        let data = [{ key: 0, name: JSON.parse(localStorage.getItem('userInfo')).phone, limited: true }]
        http.getCompanyMember().then(res => {
            // if(res.result==1){
            res.data.map((e, index) => {
                data.push({ key: index + 1, name: e, limited: false })
            })
            this.setState({
                dataSource: data
            })
            // }
        })
    }
    componentDidMount() {
        if (this.state.companyInfo.master) {
            this.getCompanyMenber()
        }
        http.getCompanyTypes().then(type => {
            http.getCompanyScales().then(scale => {
                http.getIndustry().then(industry => {
                    this.setState({
                        companyTypes: type.data,
                        companyScales: scale.data,
                        companyIndustry: industry.data,
                        // current: this.props.location.state.current
                    })
                })
            })
        })
        if (this.state.companyInfo.address) {
            http.getMapInfo({ id: this.state.companyInfo.address.province_id }).then(city => {
                http.getMapInfo({ id: this.state.companyInfo.address.city_id }).then(region => {
                    this.setState({
                        cityInfo: city.data.sub,
                        regionInfo: region.data.sub
                    })
                })
            })
        }
    }

    addAccount() {
        this.setState({
            showModal: true,
            modalType: 'addForm',
            title: '添加子账号'
        })
    }

    changePhone() {
        this.setState({
            showModal: true,
            modalType: 'changePhoneForm',
            title: '换绑手机号'
        })
    }

    onCancel = () => {
        this.setState({
            showModal: false,
            modalType: ''
        })
    }
    //换绑主账号，倒计时10秒后退出当前账号到登录页
    onChangeConfirm = () => {
        this.setState({
            showModal: false,
            modalType: ''
        })

        notification.open({
            message: '提示',
            description: <Notification history={this.props.history}></Notification>,
            duration: 10,
            onClose: () => {
                localStorage.removeItem('token');
                localStorage.removeItem('userInfo');
                this.props.history.push('/login');
            }
        });
    }

    //更换手机号后重新获取用户信息存储
    onChangePhoneComfirm = () => {
        this.setState({
            showModal: false,
            modalType: ''
        })
        message.success("换绑成功！")
        http.getCompanyInfo().then(res => {
            if (res.result === 1) {
                localStorage.setItem('userInfo', JSON.stringify(res.data))
                this.setState({
                    companyInfo: res.data
                })
            }
        })
    }

    render() {
        // const formRef = React.createRef()
        // formRef.current.setFieldsValue(this.state.companyInfo)
        console.log(this.props.location)
        return <div className='account-index'>
            <p className='title'><span onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span><span className='name'>企业管理</span><span className='status'>在这里可以修改公司信息</span></p>
            <Tabs defaultActiveKey={this.props.location.state ? this.props.location.state.current : '1'} onChange={this.handleClick}>
                <TabPane tab="企业信息" key="1">
                    <FormModule
                        transferData={{
                            companyInfo: this.state.companyInfo,
                            cityInfo: this.state.cityInfo,
                            regionInfo: this.state.regionInfo,
                            companyTypes: this.state.companyTypes,
                            companyScales: this.state.companyScales,
                            companyIndustry: this.state.companyIndustry
                        }}></FormModule>    </TabPane>
                <TabPane tab="账号管理" key="2">
                    <div className="account-box">
                        <PageHeader
                            title={<ImgNode imgLogo={this.state.companyInfo.logo}></ImgNode>}
                            subTitle={<InfoTextNode info={{ companyInfo: this.state.companyInfo, member: this.state.dataSource.length }}></InfoTextNode>}
                            extra={[
                                this.state.companyInfo.master ? <Button onClick={this.addAccount.bind(this)} type='primary' key='1' icon={<PlusOutlined />}>新增子账号</Button> :
                                    <Button onClick={this.changePhone.bind(this)} type='primary' key='1'>换绑手机号</Button>
                            ]}>
                        </PageHeader>
                        {this.state.companyInfo.master ? <Table dataSource={this.state.dataSource} columns={this.state.columns} />
                            : <div className='notify-info'>如果要离开当前企业，请联系主账号用户进行处理</div>}
                    </div>
                </TabPane>
            </Tabs>

            <Modal width={368} destroyOnClose={true} className='welfare-box' cancelText='取消' okText='确定' title={this.state.title}
                visible={this.state.showModal}
                onCancel={() => { this.setState({ showModal: false, modalType: '' }) }}
                footer={null}>
                {
                    this.state.modalType === 'addForm' ? <AddAccountForm onCancel={this.onCancel} hideModal={() => { this.setState({ showModal: false, modalType: '' }); this.getCompanyMenber() }}></AddAccountForm>
                        : this.state.modalType === 'changeForm' ? <ChangeMasterAccountForm childAccount={this.state.dataSource} onConfirm={this.onChangeConfirm} onCancel={() => { this.setState({ showModal: false, modalType: '' }) }}></ChangeMasterAccountForm>
                            : <ChangePhoneForm onConfirm={this.onChangePhoneComfirm} onCancel={() => { this.setState({ showModal: false, modalType: '' }) }}></ChangePhoneForm>
                }
            </Modal>
            <Modal width={368} destroyOnClose={true}
                title="提示"
                visible={this.state.delFalg}
                onOk={this.delMember}
                onCancel={() => { this.setState({ delFalg: false }) }}>
                <p>确定删除该成员吗？</p>
            </Modal>
        </div>;
    }
}

class ImgNode extends React.Component {
    render() {
        return <div className="logo">
            <img src={this.props.imgLogo} alt="" />
        </div>;
    }
}
class InfoTextNode extends React.Component {
    render() {
        return <div className="info">
            <p className='name'>{this.props.info.companyInfo.master ? this.props.info.companyInfo.name : this.props.info.companyInfo.phone}</p>
            <p className='num'>{this.props.info.companyInfo.master ? `当前${this.props.info.member}位成员` : '当前绑定手机号'}</p>
        </div>;
    }
}
class Notification extends React.Component {
    state = {
        time: 10,
        timer: null
    }
    componentDidMount() {
        console.log(this.props)
        this.state.timer = setInterval(() => {
            let num = this.state.time - 1;
            if (num === 0) {
                clearInterval(this.state.timer)
                localStorage.removeItem('token');
                localStorage.removeItem('userInfo');
                this.props.history.push('/login');
            } else {
                this.setState({
                    time: num
                })
            }
        }, 1000)
    }
    componentWillUnmount() {
        if (this.state.timer) {
            clearInterval(this.state.timer)
        }
    }
    render() {
        return <div>换绑成功，请使用新手机号重新登录，<span style={{ 'color': "#3188F3" }}>{this.state.time}秒</span>后跳转到登录页</div>;
    }
}


export default AccountIndex;