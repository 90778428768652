import { nullLiteral } from '@babel/types';
import { Pagination } from 'antd';
import React, { Component } from 'react';
import http from '../../http';
import TalentCollectionItem from './talent-collection-card';
import './talent-collection.less';

export default class talentCollection extends Component {
    onChangepage(pageNumber){
        console.log('Page: ', pageNumber)
    }
    constructor(props) {
        super(props);
        this.state={
            resumeList:[],
            resumeDetail:{},
            total:0,
            current:1,
            pageSize:10
        }
    }

    getPageTotal(){
        http.getStarResumeTotal().then(res=>{
            if(res.result===1){
                console.log('请求成功'+res.data)
                this.setState((total)=>{
                    return{
                        total:res.data
                    }
                })  
            }
        })
    }

    
    getResumeList(){
        if(this.state.total!=0&&this.state.resumeList==null){
            this.setState((current)=>{
                return{
                    current:this.state.current-1
                }
            })
        }
        let searchData = {
            _b: this.state.pageSize * (this.state.current - 1) + 1,
            _e: this.state.pageSize * this.state.current 
        }
        http.getStarResume(searchData).then(res=>{
            if(res.result===1){
                http.getStarResumeTotal().then(total=> {
                    if(res.result===1){
                        this.setState((resumeList)=>{
                            return{
                                resumeList:res.data,
                                total:total.data
                            }
                        })
                    }
                })        
            } 
        })
    }

    //翻页或页数改变时回调函数
    changePage = (current, size) => {
        this.setState({ current: current, pageSize: size }, () => {
            console.log(this.state.current,this.state.pageSize)
            this.getResumeList()
        })
    }

    //取消收藏后刷新
    fresh(){
        if(this.state.current!=1 && this.state.resumeList.length==1){
            this.setState({current:this.state.current-1},() =>{
                this.getResumeList()
                console.log('current2',this.state.current)
            })
        }else {
            console.log('current',this.state.current,this.state.resumeList)
            this.getResumeList()
        }
    }

    componentDidMount(){
        if(sessionStorage.getItem('page')>1){
            console.log("page",sessionStorage.getItem('page'))
            let currentpage=sessionStorage.getItem('page')
            console.log('页码',currentpage)
            this.setState({current:currentpage},() =>{
                console.log("当前页码",this.state.current)
                this.getResumeList()
                sessionStorage.removeItem("page")
                }
            )
        }else{
            this.getResumeList() 
            sessionStorage.removeItem('page')
        }
    }


    componentWillUnmount(){
        sessionStorage.setItem("page",this.state.current)
    }

    render() {
        return (
            <div>
                {this.state.resumeList.length>0?
                <div>
                    <div className="talentCollectionItemBox">
                        {this.state.resumeList.map((e,index) => {
                            return <TalentCollectionItem key={e.id} history={this.props.history} resumeDetail={e} fresh={()=>{this.fresh()}}></TalentCollectionItem>
                        })}
                    </div>
                    <div className="paginationBox">
                        <Pagination 
                            showSizeChanger
                            showQuickJumper 
                            current={this.state.current}
                            pageSizeOptions={[10,20,30]} 
                            total={this.state.total} 
                            pageSize={this.state.pageSize}
                            onChange={this.changePage} 
                            showTotal={total => `共${this.state.total}条简历`}
                        />
                    </div>
                </div>
                :
                <div className="no-data">
                    <img src={require('../../assets/no-data.png').default} alt="" />
                </div>
                }
            </div>
        )
    }
}
