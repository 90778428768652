import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import './login.less'
import AccountBar from './account-bar'
import { Col, Row, Form, Input, Button, message, Menu, } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import http from '../../http'
const { Search } = Input;

///验证码登录模块
const CodeHtml = (props) => {
  const [form] = Form.useForm();
  let history = useHistory();

  //获取验证码
  const getCode = function () {
    const values = form.getFieldsValue();
    console.log()
    if (values.username) {
      http.getCode({ send: values.username }).then(res => {
        console.log(res)
        if (res.result === 1) {
          message.success('发送成功')
          // form.setFieldsValue({
          //   code: res.data
          // })
        }
      })
    } else {
      message.info('请输入手机号');
    }
  }

  //
  const codeFinish = value => {
    console.log(props)
    http.yzmLogin(value).then(res => {
      if (res.result === 1) {
        localStorage.setItem('token', res.data);
        http.getCompanyInfo().then(res2 => {
          console.log(res2)
          localStorage.setItem('userInfo', JSON.stringify(res2.data));
          if (res2.result == 1) {
            if(res2.data.status===2||res2.data.status===3){
              history.push('/indexPage')
            }else{
              history.push('/')
            }
          }
        })
      }
    })
  }

  return (
    <Form form={form} name="normal_login" className="login-form" onFinish={codeFinish}>
      <Form.Item name="username" rules={[{ required: true, len: 11, message: '请输入手机号' }]}>
        <Input placeholder="请输入手机号" />
      </Form.Item>
      <Form.Item name="code" rules={[{ required: true, len: 6, message: '请输入验证码' }]}>
        <Row className='code-input-row'>
          <Col span='18'><Input bordered={false} placeholder="请输入验证码" /></Col>
          <Col span='6'><div onClick={getCode} className="get-code">获取验证码</div></Col>
        </Row>
      </Form.Item>
      <Form.Item className='login-btn'>
        <Button type="primary" htmlType="submit" className="code-login-form-button form-button">立即登录</Button>
        {/* <a href="">register now!</a> */}
      </Form.Item>
      <Form.Item>
        <Button onClick={() => props.register()} style={{width:"50%"}} className="code-register-form-button form-button">立即注册账号</Button>
        <Button onClick={() => {window.location.href = 'https://job.zhinanche.com'}} style={{width:'50%'}} className="code-register-form-button form-button">个人版登录</Button>
      </Form.Item>
    </Form>
  )
}

///密码登录模块
const PasswordHtml = (props) => {
  const [passForm] = Form.useForm();
  let history = useHistory();

  const passwordLoagin = values => {
    http.passwordLogin(values).then(res => {
      if (res.result === 1) {
        localStorage.setItem('token', res.data);
        http.getCompanyInfo().then(res2 => {
          console.log(res2)
          localStorage.setItem('userInfo', JSON.stringify(res2.data));
          if (res2.result == 1) {
            if(res2.data.status===2||res2.data.status===3){
              history.push('/indexPage')
            }else{
              history.push('/')
            }
          }
        })
      }
    })
  }

  return (
    <Form form={passForm} onFinish={passwordLoagin} name="normal_login" className="login-form">
      <Form.Item name="username" rules={[{ required: true, len: 11, message: '请输入手机号' }]}>
        <Input placeholder="请输入手机号" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
        <div className="code-input-row">
          <Input.Password className='pass-input' bordered={false} placeholder="请输入密码" />
        </div>
      </Form.Item>
      <Form.Item>
        {/* () => this.setState({ loginFlag: 'getPass' }) */}
        <Button type="link" onClick={() => props.forgetPassword()} block>
          忘记密码
        </Button>
      </Form.Item>
      <Form.Item className='login-btn'>
        <Button type="primary" htmlType="submit" className="password-login-form-button  form-button">立即登录</Button>
      </Form.Item>
      <Form.Item>
        <Button onClick={() => props.register()} style={{width:'50%'}} className="code-register-form-button form-button">立即注册账号</Button>
        <Button onClick={() => {window.location.href = 'https://job.zhinanche.com'}} style={{width:'50%'}} className="code-register-form-button form-button">个人版登录</Button>
      </Form.Item>
    </Form>
  )
}

///注册模块
const RegisterHtml = (props) => {
  const [registerForm] = Form.useForm();
  let history = useHistory();

  //获取验证码
  const getCode = function () {
    const values = registerForm.getFieldsValue();
    console.log()
    if (values.username) {
      http.getCode({ send: values.username }).then(res => {
        console.log(res)
        if (res.result === 1) {
          message.success('发送成功')
          // form.setFieldsValue({
          //   code: res.data
          // })
        }
      })
    } else {
      message.info('请输入手机号');
    }
  }

  const putRegister = values => {
    http.register({
      params: { username: values.username, password: values.password },
      headersData: { send: values.username, code: values.code }
    }).then(res => {
      if (res.result === 1) {
        message.success('注册成功，返回登录')
        setTimeout(props.goBack(), 2000)
      }
    })
  }

  return (
    <div className="register-right account-box">
      <Menu className='login-tab' selectedKeys={['register']} mode="horizontal">
        <Menu.Item className='login-tab-item' key="register">新用户注册</Menu.Item>
      </Menu>
      <Form form={registerForm} onFinish={putRegister} name="normal_login" className="login-form">
        <Form.Item name="username" rules={[{ required: true, len: 11, message: '请输入手机号' }]}>
          <Input placeholder="请输入手机号" />
        </Form.Item>
        <Form.Item name="code">
          {/* rules={[{ required: true, message: 'Please input your Password!' }]} */}
          <Row className='code-input-row'>
            <Col span='18'><Input bordered={false} placeholder="请输入验证码" /></Col>
            <Col span='6'><div onClick={getCode} className="get-code">获取验证码</div></Col>
          </Row>
        </Form.Item>
        <Form.Item name="password">
          <div className="code-input-row">
            <Input.Password className='pass-input' bordered={false} placeholder="请输入密码" />
          </div>
        </Form.Item>
        <Form.Item className='login-btn'>
          <Button type="primary" htmlType="submit" className="regist-form-button  form-button">立即注册</Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={() => { props.goBack() }} className="code-register-form-button form-button">已有账号，返回登录</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

///找回密码
const GetPasswordHtml = (props) => {
  const [getPasswordForm] = Form.useForm();
  let history = useHistory();

  //获取验证码
  const getCode = function () {
    const values = getPasswordForm.getFieldsValue();
    console.log()
    if (values.username) {
      http.getCode({ send: values.username }).then(res => {
        console.log(res)
        if (res.result === 1) {
          message.success('发送成功')
          // form.setFieldsValue({
          //   code: res.data
          // })
        }
      })
    } else {
      message.info('请输入手机号');
    }
  }

  const getPassword = values => {
    http.getPassword({
      params: { username: values.username, password: values.password },
      headersData: { send: values.username, code: values.code }
    }).then(res => {
      if (res.result === 1) {
        message.success('找回成功，返回登录')
        setTimeout(props.goBack(), 2000)
      }
    })
  }

  return (
    <div className="register-right account-box">
      <Menu className='login-tab' selectedKeys={['register']} mode="horizontal">
        <Menu.Item className='login-tab-item' key="register">找回密码</Menu.Item>
      </Menu>
      <Form form={getPasswordForm} onFinish={getPassword} name="normal_login" className="login-form">
        <Form.Item name="username" rules={[{ required: true, len: 11, message: '请输入手机号' }]}>
          <Input placeholder="请输入手机号" />
        </Form.Item>
        <Form.Item name="code">
          {/* rules={[{ required: true, message: 'Please input your Password!' }]} */}
          <Row className='code-input-row'>
            <Col span='18'><Input bordered={false} placeholder="请输入验证码" /></Col>
            <Col span='6'><div onClick={getCode} className="get-code">获取验证码</div></Col>
          </Row>
        </Form.Item>
        <Form.Item name="password">
          <div className="code-input-row">
            <Input.Password className='pass-input' bordered={false} placeholder="请输入密码" />
          </div>
        </Form.Item>
        <Form.Item className='login-btn'>
          <Button type="primary" htmlType="submit" className="regist-form-button  form-button">找回密码</Button>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" onClick={() => props.goBack()} className="code-register-form-button form-button">返回登录</Button>
        </Form.Item>
      </Form>
    </div>
  )
}


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      rediectToReferrer: false, // 是否重定向之前的界面
      current: 'code',  //默认是快捷登录
      loginFlag: 'login', //默认是登录页面
    };
  }

  componentDidMount() {
  }

  handleClick = e => {
    console.log('click ', e);
    this.setState({ current: e.key });
  };


  //切换到找回密码
  forgetPassword() {
    this.setState({ loginFlag: 'getPass' })
  }
  //切换到注册账号
  register() {
    this.setState({ loginFlag: 'register' })
  }
  //返回登录
  goback = () => {
    this.setState({ loginFlag: 'login' })
  }

  //登录
  LoginHtml() {
    return (
      <div className="login-right account-box">
        <Menu className='login-tab' onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
          <Menu.Item className='login-tab-item' key="code">快捷登录</Menu.Item>
          <Menu.Item className='login-tab-item' key="password">密码登录</Menu.Item>
        </Menu>
        {this.state.current === 'code' ? <CodeHtml register={this.register.bind(this)}></CodeHtml> : <PasswordHtml register={this.register.bind(this)} forgetPassword={this.forgetPassword.bind(this)}></PasswordHtml>}
      </div>
    );
  }

  render() {
    // console.log('app data')
    // console.log(this.props)
    // console.log('app data')
    return (
      <div>
        <AccountBar></AccountBar>
        <div className="login-bg" >
          <Row className='login-box' >
            <Col span='12' offset='12'>
              {
                this.state.loginFlag === 'login' ? this.LoginHtml() :
                  this.state.loginFlag === 'register' ? <RegisterHtml goBack={this.goback} ></RegisterHtml> :
                    <GetPasswordHtml goBack={this.goback}></GetPasswordHtml>
              }

            </Col>
          </Row>
        </div>
        <img className='index-title' src={require('./../../assets/account-index-title-1.png').default} alt="" />
        <div className='index-content-1'>
          <img src={require('./../../assets/account-index-content-1.png').default} alt="" />
        </div>
        <img className='index-title' src={require('./../../assets/account-index-title-2.png').default} alt="" />
        <div className='index-content index-content-2'>
          <img className='left' src={require('./../../assets/account-index-content-2.png').default} alt="" />
          <div className="right">
            <p className='border-font'><span className='theme-font'>垂直、专业</span>的</p>
            <p className='border-font'>工业机器人工程师招聘平台</p>
            <div className="line"></div>
            <p className="default-font">专注于智能制造领域，招聘更专业</p>
            <p className="lighter-font">1. 基于工业机器人用人要求，人才精准分类</p>
            <p className="lighter-font">2. 以真实就业职业技能为基准，精确技能匹配</p>
            <p className="lighter-font">3. 基于指南车认证体系，明确工程师能力等级</p>
          </div>
        </div>
        <div className='index-content index-content-3'>
          <div className="right">
            <p className='border-font'>工程师求职</p>
            <p className='border-font'>响应更<span className='theme-font'>快速</span>、就业更<span className='theme-font'>便捷</span></p>
            <div className="line"></div>
            <p className="default-font">线上线下同步推荐，帮助工程师快速就业</p>
            <p className="lighter-font">1. 专注的团队，专注的服务</p>
            <p className="lighter-font">2. 线上自动匹配，线下手动推送</p>
          </div>
          <img className='left' src={require('./../../assets/account-index-content-3.png').default} alt="" />

        </div>
        <div className='index-content index-content-4'>
          <img className='left' src={require('./../../assets/account-index-content-4.png').default} alt="" />
          <div className="right">
            <p className='border-font'>求职招聘信息</p>
            <p className='border-font'>更加<span className='theme-font'>真实</span>、更加<span className='theme-font'>放心</span></p>
            <div className="line"></div>
            <p className="default-font">专业工作人员进行信息对接，解决企业棘手的招聘问题</p>
            <p className="lighter-font">1. 企业注册严格审核，审核不通过不能发布职位</p>
            <p className="lighter-font">2. 工程师信息及时对接，信息异常及时沟通修正</p>
          </div>
        </div>
        <img className='index-title' src={require('./../../assets/account-index-title-3.png').default} alt="" />
        <img className='index-content-5' src={require('./../../assets/account-index-content-5.png').default} alt="" />
        <div className="footer">
          <Row className='footer-row' align="bottom">
            <Col span={14}>
              <p className='title'>关于机器人人才网</p>
              <p style={{ 'height': '108px' }}>机器人人才网隶属于杭州指南车机器人科技有限公司，专注于打造工业自动化系统集成领域专门的精准招聘就业平台。机器人人才网秉承为 “中国智能制造2025”提供强大的后备力量，为企业输送高端人才，为行业提供优质高效的人才推荐服务，解决就业难就业不精准等问题， 推动自动化制造业行业发展，为祖国工业化的发展贡献自己的力量！</p></Col>
            <Col offset={2} span={2.5}>
              <img className='wx-code' src={require('./../../assets/wx-code.png').default} alt="" />
            </Col>
            <Col span={5.5} className='phone'>
              {/* <p>微信扫一扫左侧二维码</p> */}
              <p>微信扫一扫左侧二维码<br />添加机器人人才网客服微信<br /><br />或拨打合作热线: <span>4008616755</span></p>
              {/* <p></p> */}
            </Col>
          </Row>
          <div className="url-link">
            <li>
              <a target="_blank" href="http://www.zhinanche.com/">指南车首页
            </a>
            </li>
            <div className="line"></div>
            <li>
              <a target="_blank" href="http://school.zhinanche.com">工程师教育</a>
            </li>
            <div className="line"></div>
            <li>
              <a target="_blank" href="http://www.zhinanche.online/">在线教育</a>
            </li>
            <div className="line"></div>
            <li>
              <a target="_blank" href="http://service.zhinanche.com">技术服务</a>
            </li>
            <div className="line"></div>
            <li>
              <a target="_blank" href="http://cert.zhinanche.com">指南车认证</a>
            </li>
            <div className="line"></div>
            <li>
              <a target="_blank" href="http://www.zhinanche.com/src/common/main.html">关于我们</a>
            </li>
          </div>
          <div className="ba">
            <p>©️Copyright 杭州指南车机器人科技有限公司 I 浙ICP备15025120号</p>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;