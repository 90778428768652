import http from './api'

var apiMap = {
  /** */
  baseUrl: 'https://test.zhinanche.com/api/v2/zhinanche-job-business-v2',
  /**
  获取活动列表
  *
  @method activityList get
  *
  [@param] {object} params 
  *
  @return {object} 请求处理回调[then(function)]
  */
  recentClass: () => http.get(`/zhinanche-school/recentClass`),
  /**
  获取职位福利列表
  *
  @method activityList get
  *
  [@param] {object} params 
  *
  @return {object} 请求处理回调[then(function)]
  */
  jobWelfare: () => http.get(`common/dict/welfare`),
  /**
  发送验证码
  *
  @method activityList post
  *
  [@param] {object} params 
  *
  @return {object} 请求处理回调[then(function)]
  */
  getCode: (params) => http.post(`/common/sms/send`, params),
  /**
  验证码登录
  *
  @method activityList post
  *
  [@param] {object} params {username,code}
  */
  yzmLogin: (params) => http.post(`/user/login/sms/v2`, params),
  /**
    密码登录
    *
    @method activityList post
    *
    [@param] {object} params {username,password}
    */
  passwordLogin: (params) => http.post(`/user/login/v2`, params),
  /**
  注册账号
  *
  @method activityList post
  *
  [@param] {object} params {username,password}
  */
  register: (data) => http.post(`/user/register`, data.params, { headersData: data.headersData }),
  /**
  找回密码
  *
  @method activityList put    
  *
  [@param] {object} params {username,password}
  */
  getPassword: (data) => http.put(`/user/password/re`, data.params, { headersData: data.headersData }),
  /**
    获取企业信息
    *
    @method activityList get    
    *
    [@param] {object} params {username,password}
    */
  getCompanyInfo: () => http.get(`/company`, { token: true }),
  /**
     获取企业规模
     *
     @method activityList get    
     *
     [@param] {object}
     */
  getCompanyScales: () => http.get(`/common/dict/scale`),
  /**
     获取企业类型
     *
     @method activityList get    
     *
     [@param] {object} 
     */
  getCompanyTypes: () => http.get(`/common/dict/company/type`),
  /**
   获取地图信息
      *
      @method activityList get    
      *
      [@param] {object} 
      */
  getMapInfo: (params) => http.get(`/common/dict/map`, { params: params }),
  /**
  修改企业信息
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  reviseCompanyInfo: (params) => http.put(`/company`, params, { token: true }),
  /**
   公司所属行业
  *
  @method activityList get    
  *
  [@param] {object} 
  */
  companyTag: () => http.get(`/common/dict/company/tag`),
  /**
   擅长专业
  *
  @method activityList get    
  *
  [@param] {object} 
  */
  getCurriculum: () => http.get(`/common/dict/position/tag/profession`),
  /**
   擅长软件   
  *
  @method activityList get    
  *
  [@param] {object} 
  */
  getSoftware: () => http.get(`/common/dict/position/tag/software`),
  /**
   熟练技能
  *
  @method activityList get    
  *
  [@param] {object} 
  */
  getSkill: () => http.get(`/common/dict/position/tag/skill`),
  /**
   擅长应用
  *
  @method activityList get    
  *
  [@param] {object} 
  */
  getApply: () => http.get(`/common/dict/position/tag/apply`),
  /**
   机器人品牌
  *
  @method activityList get    
  *
  [@param] {object} 
  */
  getBrand: () => http.get(`/common/dict/position/tag/brand`),
  /**
   获取人才的简历列表
  *
  @method activityList get    
  *
  [@param] {object} /zhinanche-app/position/v2/hot
  */
  // getResume: (params) => http.get(`/resume/search`, { params: params, token: true }),
  getResume: (params) => http.get(`/resume/search`, { params: params, token: true }),
  /**
   获取人才的简历数量
  *
  @method activityList get    
  *
  [@param] {object} 
  */
  getResumeTotal: (params) => http.get(`/resume/search/total`, { params: params, token: true }),
  /**
   获取用户发布的职位列表
  *
  @method activityList get    
  *
  [@param] {object}  {status}
  */
  getJobList: (params) => http.get(`/position`, { params: params, token: true }),
  /**
   工作经验
  *
  @method activityList get    
  *
  [@param] {object}  
  */
  getWorkYear: () => http.get(`/common/dict/work_years`),
  /**
   学历
  *
  @method activityList get    
  *
  [@param] {object}  
  */
  getEducation: () => http.get(`/common/dict/education`),
  /**
   薪资
  *
  @method activityList get    
  *
  [@param] {object}  
  */
  getSalary: () => http.get(`/common/dict/salary`),
  /**
   企业行业
  *
  @method activityList get    
  *
  [@param] {object}  
  */
  getIndustry: () => http.get(`/common/dict/company/tag`),
  /**
  发布职位
  *
  @method activityList post    
  *
  [@param] {object} 
  */
  putJob: (params) => http.post(`/position`, params, { token: true }),
  /**
  编辑职位
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  editJob: (params, eid) => http.put(`/position/${eid}`, params, { token: true }),
  /**
   获取职位详情
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getJobDetail: (eid) => http.get(`/position/${eid}`, { token: true }),
  /**
   修改职位状态
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  editJobStatus: (eid, params) => http.put(`/position/${eid}/status`, params, { token: true }),
  /**
   批量修改职位状态
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  editAllJobStatus: (params) => http.put(`/positions/status`, params, { token: true }),


  /**
   * 
    获取人才详情
   * 
    @method activityList get
   * 
    [@param] {object}
   */
  getResumeDetail: (params) => http.get(`/resume/${params}`, { token: true }),

  /**
   * 
    人才收藏（收藏）
   * 
    @method activity put
   * 
    [@param] {object}
   */
  talentStar: (id, params) => http.put(`/resume/${id}/star`, params, { token: true }),

  /**
    * 
     人才收藏（取消收藏）
    * 
     @method activity put
    * 
     [@param] {object}
    */
  talenCancletStar: (id) => http.put(`/resume/${id}/star`, { star: 0 }, { token: true }),

  /**
   * 
   收藏人才列表总数
   * 
   @method activity get
   * 
   [@param] {object}
   */
  getStarResumeTotal: () => http.get(`/resume/star/total`, { token: true }),
  /**
   * 
   企业成员列表
   * 
   @method activity get
   * 
   [@param] {object}
   */
  getCompanyMember: () => http.get(`/company/members`, { token: true }),

  /**
    * 
    添加企业成员
    * 
    @method activity POST
    * 
    [@param] {object}/company/members
    */
  addCompanyMember: (data) => http.post(`/company/members`, data.params, { headersData: data.headersData, token: true }),
  /**
    * 
    删除企业成员
    * 
    @method activity delete
    * 
    [@param] {object}/company/members
    */
  delCompanyMember: (data) => http.delete(`/company/members`, { params: data, token: true }),
  /**
    * 
    更换主账号
    * 
    @method activity PUT
    * 
    [@param] {object}/company/members
    */
  changeMaster: (data) => http.put(`/company/master`, data.params, { headersData: data.headersData, token: true }),

  /**
  *
  获取职位
  * @returns
  */

  getPosition: () => http.get(`/position`, { token: true }),

  /**
   *
   人才管理列表 
   * 
   @method activity get
   * 
   [@param] {object}
   */
  talentManageList: (params) => http.get(`/resume`, { params: params, token: true }),

  /**
   * 
   按职位和处理状态获得数量
   * 
   @method activity get
   * 
   [@param] {object}
   */
  getTotal: (params) => http.get(`/resume/total`, { params: params, token: true }),

  /**
   * 
   按职位改变处理状态
   * 
   @method activity put
   * 
   [@param] {object}
   */
  changeStatus: (eid, params) => http.put(`resume/${eid}`, params, { token: true }),

  /**
   * 
   修改用户名
   * 
   @method activity PUT
   * 
   [@param] {object}/company/members
   */
  changePhone: (data) => http.put(`/user/username`, data.params, { headersData: data.headersData, token: true }),

  getStarResume: (params) => http.get(`/resume/star`, { params: params, token: true }),
  /**
   *
   获取职位
   * @returns
   */
  getPosition: () => http.get(`/position`, { token: true }),
  /**
   * 
   简历下载()
   * 
   @method activity get
   * 
   [@param] {objecr}
   */
  resumeDownload: (id) => http.get(`/resume/${id}/download`, { token: true }),
  /**
   * 
   简历付费
   * 
   @method activity post
   * 
   [@param] {objecr}
   */
  resumeDownloadPay: (id, param) => http.post(`/resume/${id}/pay`, param, { token: true }),


}

export default apiMap;