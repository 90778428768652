import { React, Component, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Radio, Form, Input, Button, Select, Cascader, Modal, message } from "antd";
import './post-job.less'
import http from '../../http'
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
//弹窗选择职位福利
class WelfareWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectlist: [],
            allWelfareList: []
        }
    }
    // handleSendPMsg = ()=>{
    //   this.props.getMsg(this.state.val)
    // }
    componentDidMount() {
        console.log(this.props, "+++++++++++++++++")
        let data = [];
        this.props.welfareList.map((e) => {
            e.select = false;
            if (this.props.selectWelfare) {
                this.props.selectWelfare.map((item) => {
                    if (e.name === item.name) {
                        e.select = true;
                    }
                })
            }
            data.push(e)
        })
        this.setState({
            allWelfareList: data,
            selectlist: this.props.selectWelfare
        })
    }

    handleSendPMsg(index) {
        // console.log(this.state.allWelfareList)
        let data = this.state.allWelfareList;
        data[index].select = !data[index].select
        let selectData = []
        data.forEach(element => {
            if (element.select) {
                selectData.push(element)
            }
        });
        this.setState({
            allWelfareList: data,
            selectlist: selectData
        })
        this.props.postSelectWelfare(selectData)
    }
    render() {
        const selectStyle = { 'marginRight': '8px', 'marginBottom': '8px', 'borderColor': '#3188F3', 'color': '#3188F3' };
        return this.props.welfareList.map((e, index) => {
            return <li onClick={this.handleSendPMsg.bind(this, index)} className='welfare-li' style={e.select ? selectStyle : { 'marginRight': '8px', 'marginBottom': '8px' }} key={e.id}>{e.name}</li>
        })
    }
}

//职位福利展示模块
const ShowWelfareWidget = (props) => {
    // const [selectWefare, setSelectWefare] = useState([])

    console.log('刷新', props.selectWelfare)
    return props.selectWelfare.map((e, index) => {
        return <Button onClick={() => { props.delWelfare(index) }} className='welfare-li' style={{ 'marginRight': '8px', 'marginBottom': '8px' }} key={e.id}>{e.name}</Button>
    })
}
//表单
const FormMoudle = (props) => {

    // let { provinceInfo, cityInfo, regionInfo } = this.state;
    const [form] = Form.useForm();
    const jobNative = ['全职', '兼职', '实习']; //0 ,1, 2
    const { values, welfare } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);

    let history = useHistory();
    let provinceInfo = JSON.parse(localStorage.getItem('map'))
    let workYearList = JSON.parse(localStorage.getItem('work_year'))
    let educationList = JSON.parse(localStorage.getItem('eduction'))
    let salaryList = JSON.parse(localStorage.getItem('salary'))

    const [selectWelfare, setSelectWelfare] = useState([]);
    const [cityInfo, setCityInfo] = useState([]);
    const [regionInfo, setRegionInfo] = useState([]);
    let welfareData = []

    useEffect(() => {
        console.log(1, values)
        form.setFieldsValue({
            name: values.name,
            description: values.description,
            require: values.require,
            // address: values.address ? [values.address.province, values.address.city, values.address.region,] : [],
            address_str: values.address ? values.address.address : '',
            nature: values.nature,
            province: values.address ? values.address.province_id : null,
            city: values.address ? values.address.city_id : null,
            region: values.address ? values.address.region_id : null,
            work_years: values.work_years ? values.work_years.id : null,
            education: values.education ? values.education.id : null,
            salary: values.salary ? values.salary.id : null,
            // welfare:values.welfare?values.welfare.forEach(element => {
            //     return element;
            // }):null

        });

        setSelectWelfare(values.welfare)
        if(values.address){
            http.getMapInfo({ id: values.address.province_id }).then(res => {
                setCityInfo(res.data.sub)
            })
            http.getMapInfo({ id: values.address.city_id }).then(res => {
                setRegionInfo(res.data.sub)
            })
        }
    }, [values]);


    const onFinish = value => {
        console.log('Received values of form: ', value);
        let welfare = []
        console.log(selectWelfare)
        if (selectWelfare == undefined || selectWelfare.length == 0) {
            message.error("请添加职位福利")
            return;
        }
        selectWelfare.map((e) => {
            welfare.push(e.id)
        })
        let data = value;
        data.welfare = welfare.join(',')
        if(props.edit){
            //编辑
            http.editJob(data,values.id).then(res => {
                if (res.result === 1) {
                    message.success('编辑成功');
                    history.go(-1)
                }
            })
        }else{
            //添加
            http.putJob(data).then(res => {
                if (res.result === 1) {
                    message.success('发布成功');
                    history.go(-1)
                }
            })
        }
    };

    const addWelfare = () => {
        setIsModalVisible(true)
    }
    const postSelectWelfare = (res) => {
        // selectWelfare = res
        // setSelectWelfare(res)
        welfareData = res
    }

    const handleOk = (res) => {
        console.log(res)
        setSelectWelfare(welfareData)
        setIsModalVisible(false)
    }

    const handleProvinceChange = value => {
        console.log(value)
        http.getMapInfo({ id: value }).then(res => {
            setCityInfo(res.data.sub)
        })
    }

    const delWelfare = (index) => {
        console.log(index)
        // data.splice(index,1)
        // console.log(data)
        let data = selectWelfare 
        data.splice(index,1)   
        console.log(data)
        setSelectWelfare(data)
    }

    const handleCityChange = value => {
        console.log(value)
        http.getMapInfo({ id: value }).then(res => {
            setRegionInfo(res.data.sub)
        })
    }

    return (
        <div>
            <Form form={form} initialValues={values} {...layout} validateMessages={validateMessages} onFinish={onFinish}>
                <div className="title">
                    <p className="left">1</p> <div className="right">
                        <p>职位基本信息</p><p>求职者首先查看到的信息</p>
                    </div>
                </div>
                <Form.Item name='name' label="职位名称" rules={[{ required: true }]}>
                    <Input placeholder='请填写职位全称' />
                </Form.Item>
                <Form.Item className='address' label="公司地址" >
                    <Form.Item name='province' style={{ display: 'inline-block', width: 'calc(33.3% - 24px)' }} rules={[{ required: true, message: '省是必填选项！' }]}>
                        <Select placeholder='请选择省' onChange={handleProvinceChange}>
                            {provinceInfo.map(province => (
                                <Option key={province.id} value={province.id}>{province.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <span style={{ display: 'inline-block', width: '24px' }}></span>
                    <Form.Item name='city' style={{ display: 'inline-block', width: 'calc(33.3% - 24px)' }} rules={[{ required: true, message: '市是必填选项！' }]}>
                        <Select placeholder='请选择市' onChange={handleCityChange}>
                            {cityInfo.map(e => (
                                <Option key={e.id} value={e.id}>{e.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <span style={{ display: 'inline-block', width: '24px' }}></span>
                    <Form.Item name='region' style={{ display: 'inline-block', width: 'calc(33.3%)' }} rules={[{ required: true, message: '区是必填选项！' }]}>
                        <Select placeholder='请选择区'>
                            {regionInfo.map(e => (
                                <Option key={e.id} value={e.id}>{e.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item name='address_str' label="详细地址" rules={[{ required: true }]}>
                    <Input placeholder='请填写详细地址' />
                </Form.Item>
                <div className="title" style={{ 'paddingTop': '24px' }}>
                    <p className="left">2</p> <div className="right">
                        <p>职位要求</p><p>我们将根据您的需求给你推荐合适的人才</p>
                    </div>
                </div>
                <Form.Item name='description' label="职位描述" rules={[{ required: true }]}>
                    <Input.TextArea autoSize={{ minRows: 6 }} placeholder='请填写职位描述' />
                </Form.Item>
                <Form.Item name='require' label="任职要求" rules={[{ required: true, }]}>
                    <Input.TextArea autoSize={{ minRows: 6 }} placeholder='请输入任职要求' />
                </Form.Item>
                <Form.Item
                    className='job-type'
                    name="nature"
                    label="工作性质"
                    rules={[{ required: true }]}
                >
                    <Radio.Group>
                        {jobNative.map((e, index) => {
                            return <Radio.Button value={index} key={e}>{e}</Radio.Button>
                        })}
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="work_years"
                    label="工作经验"
                    wrapperCol={{ span: 12 }}
                    rules={[{ required: true, message: '请选择工作经验' }]}>
                    <Select placeholder="请选择">
                        {
                            workYearList.map((e, index) => {
                                return <Option key={e.id} value={e.id}>{e.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="education"
                    label="学历要求"
                    wrapperCol={{ span: 12 }}
                    rules={[{ required: true, message: '请选择学历要求' }]}>
                    <Select placeholder="请选择">
                        {
                            educationList.map((e, index) => {
                                return <Option key={e.id} value={e.id}>{e.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="salary" label="薪资范围" wrapperCol={{ span: 12 }} rules={[{ required: true, message: '请选择薪资范围' }]}>
                    <Select placeholder="请选择">
                        {
                            salaryList.map((e, index) => {
                                return <Option key={e.id} value={e.id}>{e.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item className='job-type' name='welfare' label="职位福利" >
                    <ShowWelfareWidget delWelfare={delWelfare} selectWelfare={selectWelfare ? selectWelfare : []}></ShowWelfareWidget>

                    <Button type="dashed" onClick={addWelfare}>+ 编辑福利</Button>
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                    简历将发送到{JSON.parse(localStorage.getItem('userInfo')).mail}，如需修改简历接收邮箱，请前往企业个人信息页
                    </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                    <Button type="primary" htmlType="submit">
                    {props.edit ? '保存' : '发布'}
                        </Button>
                </Form.Item>
            </Form>
            <Modal width={700} className='welfare-box' cancelText='取消' okText='确定' title="职位福利" visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)}>
                <WelfareWidget postSelectWelfare={postSelectWelfare} selectWelfare={values.welfare ? values.welfare : []} welfareList={welfare}></WelfareWidget>
                {/* {welfare.map((e,index)=>{
                    return <Button className='welfare-btn' style={{'margin':'5px'}} key={e.id}>{e.name}</Button>
                })} */}
            </Modal>
        </div>
    );
};

const residences = [
    {
        value: 'zhejiang',
        label: 'Zhejiang',
        id: 1,
        children: [
            {
                value: 'hangzhou',
                label: 'Hangzhou',
                id: 2,
                children: [
                    {
                        value: 'xihu',
                        label: 'xihu',
                        id: 3,
                    },
                ],
            },
        ],
    },
    {
        value: 'jiangsu',
        label: 'Jiangsu',
        id: 4,
        children: [
            {
                value: 'nanjing',
                label: 'Nanjing',
                id: 5,
                children: [
                    {
                        value: 'zhonghuamen',
                        label: 'zhonghuamen',
                        id: 6,
                    },
                ],
            },
        ],
    },
];
const validateMessages = {
    required: '${label}是必填选项!',
    types: {
        email: '请输入${label}!',
    },
};


// const [form] = Form.useForm();


class PostJobPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            initialValues: {},
            welfare: {}
        }
    }



    componentDidMount() {
        console.log(this.props.location.state)
        if(this.props.location.state){
            if (this.props.location.state.edit) {
                let requireData = this.props.location.state.data
                this.setState({
                    initialValues: requireData,
                    edit: this.props.location.state.edit,
                })
            }
        }
        http.jobWelfare().then(res => {
            if (res.result == 1) {
                this.setState({
                    welfare: res.data,
                })
            }
        })
    }



    render() {
        const { formData, initialValues } = this.state;
        return <div className='post-job'>
            <div className="top-body">
                <span onClick={() => { this.props.history.go(-1) }}>职位管理</span><span className="italic-line">/</span><span className='theme-color'>{this.state.edit ? '编辑' : '发布'}职位</span>
            </div>
            <div className="content-body">

                <FormMoudle edit={this.state.edit} values={initialValues} welfare={this.state.welfare}></FormMoudle>

            </div>
        </div>;
    }
}


export default PostJobPage;