import React, { Component } from 'react'
import { Tabs,Badge,Button,TabPaneProps, Tag,Pagination,Row,Col} from 'antd';
import TalentManageCard from './talent-manage-card'
import './talent-manage.less'
import http from '../../http'

export default class talentManage extends Component {
    constructor(props) {
        super(props);
        this.state={
            positionList:[],
            resumeList:[],
            resumeDetail:{},
            selectPosition:0,
            show:true,
            status:'1',
            total:0,
            current:1,
            pageSize:10,
            statusnumber1:0,
            statusnumber2:0,
            statusnumber3:0
        }
        this.getPositionList()
        this.getResumeList()
    }
    //获取三种状态的人才数量
    getPageTotal(){
        let param
        if(this.state.selectPosition!=0){
            param = {
                position_id:this.state.positionList[this.state.selectPosition].id,   
                status:1
            }
        }else {
            param = {
                status:1
            }
        }
            http.getTotal(param).then(res1=>{
                if(res1.result===1){
                    param.status=2
                    http.getTotal(param).then(res2=>{
                        if(res2.result===1){
                            param.status=3
                            http.getTotal(param).then(res3=>{
                                if(res3.result===1){
                                    param.status=4
                                    http.getTotal(param).then(res4=>{
                                        if(res4.result===1){
                                            this.setState({
                                                statusnumber1:res1.data,
                                                statusnumber2:res2.data,
                                                statusnumber3:res3.data+res4.data,
                                            },()=>{
                                                this.getPage()
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })    
        }
    //判定页码数
    getPage(){
        if(this.state.status==1){
            let status=this.state.statusnumber1
            this.setState((total)=>{
                return{
                    total:status
                }
            })
        }else if(this.state.status==2){
            let status=this.state.statusnumber2
            this.setState({
                total:status
            })
        }else {
            let status=this.state.statusnumber3
            this.setState({
                total:status
            }) 
        }
    }
    //获取职位列表
    getPositionList(){
        http.getPosition().then(res=>{
            if(res.result===1){
                res.data.unshift({name:'全部职位',status:1,id:"",key:0})
                this.setState((positionlist)=>{
                    return{
                        positionList:res.data
                    }
                })
            }
        })   
    }
    //职位列表选择改变
    positionChange=(e,index)=>{
       this.setState(
            {selectPosition: index},()=>{
                this.getResumeList()
            }
        )        
    }
    //职位状态选择改变
    positionStatusChange=(e,key)=>{
        this.setState(
            {status:key},()=>{
                this.getResumeList()
            }
        )
    }
    //获取人才列表
    getResumeList(){
        let param
        if(this.state.selectPosition!=0){
            param = {
                position_id:this.state.positionList[this.state.selectPosition].id,
                status:this.state.status,
                _b: this.state.pageSize * (this.state.current - 1) + 1,
                _e: this.state.pageSize * this.state.current
            }
        }else {
            param = {
                status:this.state.status, 
                _b: this.state.pageSize * (this.state.current - 1) + 1,
                _e: this.state.pageSize * this.state.current
            }
        }      
        let total ={}
        http.talentManageList(param).then(res=>{
            if(res.result==1){
                this.setState(
                    {resumeList:res.data},()=>{
                        this.getPageTotal()     
                    }
                )
            }
        })
    }



    //翻页或页数改变时回调函数
    changePage = (current, size) => {
        this.setState({ current: current, pageSize: size }, () => {
            console.log(this.state.current,this.state.pageSize)
            this.getResumeList()
        })
    }

    render() {
        const {positionList}=this.state

        return (
           
            <div className='talentManage'>
                <div className="positionBox">
                    {positionList.map((e,index)=>{
                        return (<Row gutter={[16,24]} wrap={true} span={20} key={index}>
                            <Col flex='auto' span={4} md={6} >
                                    <div    key={index} 
                                            onClick={ (ev)=>this.positionChange(ev,index)}
                                            className={this.state.selectPosition === index ?
                                            "position select":"position"}>
                                                {e.name} {e.status==1  ? null:'（已下线）'}
                                    </div>  
                                </Col>
                            </Row>
                                )
                            })
                        }
                </div>

                <div className="tagBox">
                    <div className="tagBoxCenter">
                        <div    key='1' 
                                onClick={ (ev)=>this.positionStatusChange(ev,1)}
                                className={this.state.status == 1 ?
                                "tag select":"tag"}
                            >
                            <div className="title">
                                待处理
                            </div>
                            <div className="badge">
                                {this.state.statusnumber1}
                            </div>
                        </div>
                        <div    key='2' 
                                onClick={ (ev)=>this.positionStatusChange(ev,2)}
                                className={this.state.status == 2 ?
                                "tag select":"tag"}
                            >
                            <div className="title">
                                沟通中
                            </div>
                            <div className="badge">
                                {this.state.statusnumber2}
                            </div>
                        </div>
                        <div    key='3' 
                                onClick={ (ev)=>this.positionStatusChange(ev,'3,4')}
                                className={this.state.status == '3,4' ?
                                "tag select":"tag"}
                            >
                            <div className="title">
                                已处理
                            </div>
                            <div className="badge">
                                {this.state.statusnumber3}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.resumeList.length>0?
                <div>
                <div>
                    {this.state.resumeList.map((e,index) => {
                        return <TalentManageCard    
                                        jumpJobDetaiil= {() => { this.props.history.push({
                                                                 pathname: '/resume',  
                                                                 state: { id: e.id } 
                                                                }) 
                                                            }
                                                        }
                                        key={index} 
                                        resumeDetail={e} 
                                        mark={this.state.status}
                                        fresh={()=>{this.getResumeList()}}
                                        
                                    >
                               </TalentManageCard>
                        })
                    }
                </div>
                <div className="paginationBox">
                    <Pagination 
                        showSizeChanger
                        showQuickJumper 
                        current={this.state.current}
                        pageSizeOptions={[10,20,30]} 
                        total={this.state.total} 
                        pageSize={this.state.pageSize}
                        onChange={this.changePage} 
                        showTotal={total => `共${this.state.total}条简历`}
                    />
                </div>
            </div>
            :
            <div className='no-data1'>
                <img src={require('../../assets/no-data.png').default} alt="" />
            </div>
            }
        </div>
        )
    }
}
