
import detail from './../components/detail'
import error from './../components/error'
import login from './../components/account/login'
// import companyIndex from '../components/company-index'
import firstPage from '../components/index-page/first-page'
import jobManege from './../components/index-page/job-manege'
import companyBaseInfo from './../components/account/company-base-info'
/*引入简历模块测试 */
import resumeDetail from './../components/resume/resume-detail'
/*引入人才收藏测试 */
import talentCollection from '../components/talent-collection/talent-collection'
/*引入人才管理模块*/
import talentManage from '../components/talent-manage/talent-manage'
import postJob from '../components/job/post-job'
import jobDeatail from '../components/job/job-detail'
import accountIndex from '../components/account/account-index'

//auth  路由是否需要校验登录状态
var routerMap = [
    {
        path: "/login", name: "login", component: login
    },
    {
        path: "/", name: "companyBaseInfo", component: companyBaseInfo, auth: true
    },
    {
        path: "/indexPage", exact: true,name: "firstPage", component: firstPage, auth: true, title: '首页',layout:true
    },
    {
        path: "/jobManege", name: "jobManege", component: jobManege, auth: true,title: '职位管理',layout:true
    },
    // 发布职位
    {
        path: "/jobManege/postJob", name: "postJob", component: postJob, auth: true,title: '职位管理',layout:true
    },
    // 职位详情 
    {
        path: "/jobManege/jobDetail", name: "jobDetail", component: jobDeatail, auth: true,title: '职位管理',layout:true
    },
    // 企业管理 
    {
        path: "/accountIndex", name: "accountIndex", component: accountIndex, auth: true,title: '企业管理',layout:true
    },
    /* 简历测试路由 */
    {
        path: "/resume",name:"resume",component: resumeDetail,title:'简历详情',layout:true
    },
    /* 人才收藏测试路由 */
    {
        path: "/resume",name:"resume",component: resumeDetail,title:'简历详情',layout:true
    },
    {
        path: "/talentCollection",name:"talentCollection",component: talentCollection,title:'人才收藏',layout:true
    },
    /* 人才管理测试路由 */
    {
        path: "/talentManage",name:"talentManage",component: talentManage,title:'人才管理',layout:true
    },
    {
        path: "/404", name: "404", component: error
    },

];
export default routerMap;


