import { React, Component } from 'react'
import { Pagination, Input, Row, Col } from 'antd'
import './first-page.less'
import {
    DownOutlined,
    UpOutlined
} from '@ant-design/icons';
import JobLiHtml from "../../public/base-job-item-widget";
import http from '../../http'



const { Search } = Input;

class FirstPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchName: '',
            hopSearch: ['机械', '电气', '调试', '销售'],
            softWareId: 0,
            curriculumId: 0,
            skillId: 0,
            applyId: 0,
            brandId: 0,
            curriculumList: [{ id: 0, name: '不限' }],
            softWareList: [{ id: 0, name: '不限' }],
            skillList: [{ id: 0, name: '不限' }],
            applyList: [{ id: 0, name: '不限' }],
            brandList: [{ id: 0, name: '不限' }],
            resumeList: [],
            resumeDetail: {},
            total: 0,
            current: 1,
            pageSize: 10,
            tagFlag: []
        };
    }

    componentDidMount() {
        this.getResumeData();
        http.getCurriculum().then(res1 => {
            let data1 = this.state.curriculumList.concat(res1.data)
            http.getSoftware().then(res2 => {
                let data2 = this.state.softWareList.concat(res2.data)
                http.getSkill().then(res3 => {
                    let data3 = this.state.skillList.concat(res3.data)
                    http.getApply().then(res4 => {
                        let data4 = this.state.skillList.concat(res4.data)
                        http.getBrand().then(res5 => {
                            let data5 = this.state.skillList.concat(res5.data)
                            let flag = [this.checkLength(data1), this.checkLength(data2), this.checkLength(data3), this.checkLength(data4), this.checkLength(data5)]
                            this.setState({
                                curriculumList: data1,
                                softWareList: data2,
                                skillList: data3,
                                applyList: data4,
                                brandList: data5,
                                tagFlag: flag
                            })
                        })
                    })
                })
            })
        })
    }

    //判断数据长度
    checkLength(data) {
        if (data) {
            return data.length > 10 ? true : false
        } else {
            return false
        }
    }

    //获取简历
    getResumeData() {
        let searchData = { _b: this.state.pageSize * (this.state.current - 1) + 1, _e: this.state.pageSize * this.state.current }
        let searchDataTotal = {}
        if (this.state.searchName.length > 0) {
            searchData.keywords = this.state.searchName
            searchDataTotal.keywords = this.state.searchName
        }
        let tagIdList = [this.state.softWareId, this.state.curriculumId, this.state.skillId, this.state.applyId, this.state.brandId]
        let tag = []
        tagIdList.map((e) => {
            if (e != 0) {
                tag.push(e)
            }
        })
        if (tag.length > 0) {
            searchData.tag = tag.join(',')
            searchDataTotal.tag = tag.join(',')
        }
        http.getResume(searchData).then(res => {
            http.getResumeTotal(searchDataTotal).then(total => {
                this.setState({
                    total: total.data,
                    resumeList: res.data
                })
            })
        })
    }
    inputChange(e) {
        this.setState({ searchName: e.target.value })
    }
    hotSearch(e) {
        this.setState({ searchName: e })
    }
    onSearch() {
        console.log(this.state.searchName)
        this.getResumeData();
    }

    //筛选标签
    swicthType(type, id) {
        this.setState({
            [type + 'Id']: id
        }, () => {
            this.getResumeData()
        })
    }

    //tag
    TagHtml(type, index) {
        let title, data = [], selectId;
        switch (type) {
            case 'curriculum':
                title = '擅长专业'
                data = this.state.curriculumList
                selectId = this.state.curriculumId
                break;
            case 'softWare':
                title = '擅长软件'
                data = this.state.softWareList
                selectId = this.state.softWareId
                break;
            case 'skill':
                title = '熟练技能'
                data = this.state.skillList
                selectId = this.state.skillId
                break;
            case 'apply':
                title = '擅长应用'
                data = this.state.applyList
                selectId = this.state.applyId
                break;
            case 'brand':
                title = '机器人品牌'
                data = this.state.brandList
                selectId = this.state.brandId
                break;
            default:
                break
        }
        return (
            <div className="tag-li">
                <div className="title">{title}</div>
                <div className="tag-ul">
                    <div className="tags">
                        {data.map((e, i) => {
                            if (i <= 10) {
                                return <div onClick={this.swicthType.bind(this, type, e.id)} key={e.id} className={selectId === e.id ? "select tag-item" : "tag-item"}>{e.name}</div>
                            }
                        }
                        )}
                        {data.length > 11 ? <div className='tag-item btn' onClick={() => {
                            let flag = this.state.tagFlag;
                            flag[index] = !flag[index]
                            this.setState({
                                tagFlag: flag
                            })
                        }}>
                            <Row>
                                <Col>{this.state.tagFlag[index] ? '全部' : '收起'}</Col>
                                <Col>{this.state.tagFlag[index] ? <DownOutlined /> : <UpOutlined />}</Col>
                            </Row>
                        </div> : null}
                    </div>
                    {data.length > 10 ?
                        <div style={this.state.tagFlag[index] ?{'height':'0'}:data.length>20?{'height':'60px'}:{'height':'30px'}} className="show-tags tags">
                            {data.map((e, i) => {
                                if (i > 10) {
                                    return <div onClick={this.swicthType.bind(this, type, e.id)} key={e.id} className={selectId === e.id ? "select tag-item" : "tag-item"}>{e.name}</div>
                                }
                            }
                            )}
                        </div> : null}
                </div>
            </div>
        )
    }

    //翻页或页数改变时回调函数
    changePage = (current, size) => {
        this.setState({ current: current, pageSize: size, }, () => {
            this.getResumeData();
        })
        document.getElementsByClassName('site-layout')[0].scrollTop = 0
        // document.getElementsByClassName('site-layout')[0].scrollTo({top:0,behavior:'smooth'})
    }





    render() {
        const { curriculumId, softWareId, skillId, applyId, brandId } = this.state
        return (
            <div className='first-page' id='first-page'>
                <div className="top">
                    <img src={require('./../../assets/first-page-bg.png').default} alt="" />
                    <Search enterButton="搜  索" size='large' value={this.state.searchName} onChange={this.inputChange.bind(this)} className='search-input' placeholder="请输入职位名称" onSearch={this.onSearch.bind(this)} />
                    <p>热门搜索：
                        {this.state.hopSearch.map((e) => <span key={e} onClick={this.hotSearch.bind(this, e)}>{e}</span>)}
                    </p>
                </div>
                <div className="tag-box">
                    {this.TagHtml('curriculum', 0)}
                    {this.TagHtml('softWare', 1)}
                    {this.TagHtml('skill', 2)}
                    {this.TagHtml('apply', 3)}
                    {this.TagHtml('brand', 4)}
                </div>
                {this.state.resumeList.map((e, index) => {
                    return <JobLiHtml jumpJobDetaiil={() => { this.props.history.push({ pathname: '/resume', state: { id: e.id } }) }} key={e.id} resumeDetail={e}></JobLiHtml>

                })}
                <Pagination
                    total={this.state.total}
                    pageSize={this.state.pageSize}
                    showSizeChanger
                    showQuickJumper
                    current={this.state.current}
                    pageSizeOptions={[10, 20, 30]}
                    onChange={this.changePage}
                    showTotal={total => `共 ${this.state.total} 条简历`}
                />
                {/* <Button href='/'> 返回主页 </Button>
                <Button onClick={()=>{this.props.history.push('/')}}> 返回主页 </Button> */}
            </div>
        );
    }
}

export default FirstPage;