import React, { Component } from 'react';
import { Row, Col, Menu, Dropdown } from 'antd';
import { Link, } from "react-router-dom";
import {
    LogoutOutlined,DownOutlined
} from '@ant-design/icons';
import './account-bar.less'

class Account extends Component {
    state = {}
    navMenuClick() {

        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
    }
    render() {

        const menu = (
            <Menu onClick={this.navMenuClick.bind(this)} className='drop-down' style={{'width':'130px'}}>
                <Menu.Item key="0" icon={<LogoutOutlined />}>
                    <Link to="/login">退出登录</Link>
                </Menu.Item>
            </Menu>
        );
        return (<div className='bar'>
            <Row className='navRow' justify='center'>
                <Col span='20'>
                    <img src={require('./../../assets/logo.png').default} alt="" className="znc-logo" />
                </Col>
                <Col className='phone' span='4'>
                    {localStorage.getItem('token') ?
                        <Dropdown overlay={menu}>
                            <a style={{ 'color': '#fff' }} onClick={e => e.preventDefault()}>
                                    {localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')).phone:''}  <DownOutlined />
                            </a>
                        </Dropdown> : <p>客服电话：4008616755</p>

                    }</Col>
            </Row>
        </div>);
    }
}

export default Account;