import axios from 'axios'
import { message } from 'antd'

// let hide = null
//创建axios实例，在这里可以设置请求的默认配置
const instance = axios.create({
    headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
    },
    timeout: 10000, // 设置超时时间10s
    baseURL: 'https://api.zhinanche.com/v2/zhinanche-job-business-v2' //正式
    // baseURL: 'https://test.zhinanche.com/api/v2/zhinanche-job-business-v2' //测试
})

let httpCode = {
    400: '请求参数错误',
    401: '权限不足, 请重新登录',
    403: '服务器拒绝本次访问',
    404: '请求资源未找到',
    500: '内部服务器错误',
    501: '服务器不支持该请求中使用的方法',
    502: '网关错误',
    504: '网关超时'
}

/** 添加请求拦截器 **/
instance.interceptors.request.use(config => {

    let url = config.url;
// console.log(config)
    if (config.hasOwnProperty("token") && localStorage.getItem('token')) config.headers['token'] = localStorage.getItem('token')

    if (config.hasOwnProperty("contentJson")) {
        config.headers["Content-Type"] = "application/json";
        config.dataType = 'json'
    }

    if (config.hasOwnProperty("headersData")) {
        for (let i in config.headersData) config.headers[i] = config.headersData[i];
    }
    // hide = message.loading({content: 'Loading...', duration: 0});
    // 导出文件的接口，因为返回的是二进制流，所以需要设置请求响应类型为blob
    if (config.url.includes('pur/contract/export')) {
        config.headers['responseType'] = 'blob'
    }
    if (url.indexOf("img") == -1 && !config.hasOwnProperty("contentJson")) {
        // console.log('++++++++++++++++++++++++++++++++++++++++')
        config.transformRequest = [function (data) {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
        }];
    }
    // 文件上传，发送的是二进制流，所以需要设置请求头的'Content-Type'
    if (config.url.includes('pur/contract/upload')) {
        config.headers['Content-Type'] = 'multipart/form-data'
    }

    // if (config.hasOwnProperty("contentJson")) {
    //     config.headers["Content-Type"] = "application/json";
    //     config.dataType = 'json'
    // }
    return config
}, error => {
    // 对请求错误做些什么
    console.log('error')
    return Promise.reject(error)
})

/** 添加响应拦截器  **/
instance.interceptors.response.use(response => {
    // hide()
    if (response.status === 200) {
        return Promise.resolve(response.data)
    } else {
        message.error('响应超时')
        return Promise.reject(response.data.message)
    }
}, error => {
    console.log(error.response)
    // hide()
    if (error.response) {
        // 根据请求失败的http状态码去给用户相应的提示
        if(error.response.data.errors){
            if(error.response.data.errors.code!=40004&&error.response.data.errors.code!=40005){
                message.error(error.response.data.errors.message)
            }
        }else{
            message.error('网络错误')
        }
        if (error.response.status === 401) {
            // token或者登陆失效情况下跳转到登录页面，根据实际情况，在这里可以根据不同的响应错误结果，做对应的事
            //针对框架跳转到登陆页面
            // this.props.history.push('/login');
        }
        return Promise.reject(error)
    } else {
        message.error('请求超时, 请刷新重试')
        return Promise.reject('请求超时, 请刷新重试')
    }
})


export default instance;