
import { React, Component, useState } from 'react'
import { Checkbox, Button, message } from 'antd'
import './job-manege.less'
import {
    CloudDownloadOutlined,
    EditOutlined,
    DeleteOutlined,
    SendOutlined
} from '@ant-design/icons';
import http from '../../http'
import common from '../../public/base'
import Modal from 'antd/lib/modal/Modal';


const CheckboxGroup = Checkbox.Group;


class JobManege extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobList: [],
            tabList: [
                { name: '全部职位', number: 0, },
                { name: '正在招聘的职位', number: 0, },
                { name: '已下线的职位', number: 0, },],
            selectTab: 0,
            checkList: [],
            checkedList: [],
            indeterminate: false,
            checkAll: false,
            allId: [],
            delFlag: false,
            delId: '',
            allDelFlag: false
        }
    }


    componentDidMount() {
        this.getJobList()
    }

    //获取数据列表
    getJobList = ({ id } = {}) => {
        let data = {}
        if (id) data.status = id
        http.getJobList(data).then(res => {
            if (res.result === 1) {
                if (res.data) {
                    let list = []
                    let id = []
                    res.data.map((e, index) => {
                        list.push({
                            disabled: false,
                            value: e.id,
                            label: <div key={index} className="item">
                                <div className="item-top">
                                    <button className="left" onClick={() => { this.props.history.push({ pathname: '/jobManege/jobDetail', state: { id: e.id } }) }}>
                                        <div className='status-job'>
                                            <p className="name">{e.name}</p>
                                            <div className={e.status === 2 ? 'out-line' : null}>{e.status === 1 || e.status === 3 ? '正在招聘' : e.status === 2 ? '已下线' : ''}</div>
                                        </div>
                                        <div className="mesege-center">
                                            {e.salary.name} <span></span>
                                            {e.address.province}-{e.address.city} <span></span>
                                            {e.work_years.name}
                                        </div>
                                    </button>
                                    {
                                        e.apply_user != null ? <div className="center" onClick={() => { this.props.history.push('/talentManage') }}>
                                            <div className="imgs">
                                                {e.apply_user.map((item, imgIndex) => {
                                                    if (imgIndex < 3) {
                                                        return <img style={{ 'left': e.apply_user.length >= 3 ? 32 * imgIndex + 'px' : e.apply_user.length == 2 ? 32 * (imgIndex + 1) + 'px' : '60px' }} src={item.head_img} alt="" key={item.id} />
                                                    }
                                                })}
                                            </div>
                                            <p className='text'><span>{e.apply_user.length}</span>人待筛选</p>
                                        </div> : <p className='get-people' onClick={() => { this.props.history.push('/') }}>暂无投递，去寻找人才  &gt;</p>
                                    }
                                    <div className="right">
                                        <Button type='primary' disabled={e.status === 2 ? true : false} onClick={this.refresh.bind(this, e.id)}>职位刷新</Button>
                                        <Button disabled={e.status === 2 ? true : false} onClick={this.jobTop.bind(this, e)} className={e.status === 2 ? '' : 'effect-btn'}>{e.top === 0 ? '职位置顶' : '取消置顶'}</Button>
                                    </div>
                                </div>
                                <div className="item-bottom">
                                    <div className="time">更新时间：{common.dateFormat(e.update_time, 'yyyy.MM.d h:m:s')}</div>
                                    <div className="btn-box">
                                        <button onClick={this.editJob.bind(this, e)} className='edit btn'><EditOutlined /><span>编辑职位</span></button>

                                        <button onClick={this.outlineJob.bind(this, e)} className='out btn'><CloudDownloadOutlined /><span>{e.status === 2 ? '上线职位' : '下线职位'}</span></button>
                                        <button onClick={() => this.setState({
                                            delFlag: true,
                                            delId: e.id
                                        })} className='del btn'><DeleteOutlined /><span>删除职位</span></button>
                                    </div>
                                </div>
                                {e.top === 1 ? <img className='top' src={require('../../assets/job-top.png').default} alt="" /> : null}
                            </div>
                        })
                        id.push(e.id)
                    })
                    this.setState({
                        checkList: list,
                        allId: id,
                    })

                    this.getJobToatal()
                }
            }
        })
    }

    //获取职位数量
    getJobToatal() {
        let numAll = 0
        let numNow = 0
        let numOut = 0
        http.getJobList().then(res => {
            res.data.map((e, index) => {
                if (e.status == 1 || e.status === 3) {
                    numNow += 1
                } else if (e.status === 2) {
                    numOut += 1
                }
            })
            this.setState({
                tabList: [
                    { name: '全部职位', number: res.data.length },
                    { name: '正在招聘的职位', number: numNow, },
                    { name: '已下线的职位', number: numOut, },]
            })
        })
    }

    //选中职位
    onCheckChange = e => {
        this.setState({
            checkedList: e
        })
    }
    //全选
    onCheckAllChange = e => {
        console.log(e)
        if (!this.state.checked) {
            this.setState({ checkedList: this.state.allId, checked: true, indeterminate: true })
        } else {
            this.setState({ checkedList: [], checked: false, indeterminate: false })
        }

        // setCheckedList(e.target.checked ? plainOptions : []);
        // setIndeterminate(false);
        // setCheckAll(e.target.checked);
    };

    //切换tab栏
    clickTabs(index) {
        if (this.state.selectTab === index) return;
        if (index === 0) {
            this.getJobList()
        } else if (index === 1 || index === 3) {
            this.getJobList({ id: '1,3' })
        } else {
            this.getJobList({ id: 2 })
        }
        this.setState({
            selectTab: index
        })

    }

    goPostJob() {
        this.props.history.push({ pathname: '/jobManege/postJob', state: { data: {}, edit: false } })
    }

    //职位刷新
    refresh(id) {
        console.log(id)
        http.editJobStatus(id).then(res => {
            message.success('刷新成功！')
        })
    }
    //批量刷新
    allRefresh = () => {
        console.log(this.state.checkedList)
        http.editAllJobStatus({ position_id: this.state.checkedList.join(',') }).then(res => {
            message.success('刷新成功！')
            this.getJobList()
        })
    }
    //批量删除
    allDelJob = () => {
        console.log(this.state.checkedList)
        http.editAllJobStatus({ position_id: this.state.checkedList.join(','), status: 0 }).then(res => {
            message.success('删除成功！')
            this.getJobList()
            this.setState({
                allDelFlag:false
            })
        })
    }
    //职位置顶
    jobTop(e) {
        let data = {}
        if (e.top === 0) {
            data.top = 1
        } else {
            data.top = 0
        }

        http.editJobStatus(e.id, data).then(res => {
            message.success(e.top === 0 ? '置顶成功！' : '取消成功!')
            this.getJobList()
        })
    }

    //编辑职位
    editJob(e) {
        this.props.history.push({ pathname: '/jobManege/postJob', state: { data: e, edit: true } })
    }

    //下线职位
    outlineJob(e) {
        let data = {}
        if (e.status === 2) {
            data.status = 1
        } else if (e.status === 1 || e.status === 3) {
            data.status = 2
        }
        http.editJobStatus(e.id, data).then(res => {
            message.success(e.status === 2 ? '上线成功！' : '下线成功！')
            this.getJobList()
        })
    }

    //删除职位
    delJob() {
        http.editJobStatus(this.state.delId, { status: 0 }).then(res => {
            message.success('删除成功！')
            this.getJobList()
            this.setState({
                delFlag: false
            })
        })
    }

    render() {

        return (
            <div className='job-manege'>
                <div className="nav">
                    <div className="tabs">
                        {this.state.tabList.map((e, index) => {
                            return <div key={index} onClick={this.clickTabs.bind(this, index)} className={this.state.selectTab === index ? "tab-li select-tab" : "tab-li"}>{e.name}<div className="tab-circle">{e.number}</div></div>

                        })}
                    </div>
                    <Button type='primary' onClick={this.goPostJob.bind(this)}><SendOutlined />发布职位</Button>
                </div>
                {
                    this.state.checkList.length > 0 ? <div>

                        <CheckboxGroup options={this.state.checkList} value={this.state.checkedList} onChange={this.onCheckChange} >
                        </CheckboxGroup>
                        <div className='footer'>
                            <Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckAllChange} checked={this.state.checkAll}>
                                全选
                            </Checkbox>
                            <p onClick={this.allRefresh} className="all-refresh">批量刷新</p><span className='line'></span>
                            <p onClick={()=>this.setState({allDelFlag:true})} className="all-del">批量删除</p>
                        </div>
                    </div> : <div className='no-data'>
                        <img src={require('../../assets/no-data.png').default} alt="" />
                    </div>
                }
                <Modal
                    visible={this.state.delFlag}
                    okText='确定'
                    cancelText='取消'
                    title='提示' onCancel={() => this.setState({ delFlag: false })}
                    onOk={this.delJob.bind(this)}><p>您确定删除该职位吗？</p></Modal>
                <Modal
                    visible={this.state.allDelFlag}
                    okText='确定'
                    cancelText='取消'
                    title='提示' onCancel={() => this.setState({ allDelFlag: false })}
                    onOk={this.allDelJob}><p>您确定删除选中的职位吗？</p></Modal>
            </div>
        );
    }
}

export default JobManege;