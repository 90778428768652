import React, { Component } from 'react';
import { CheckOutlined } from '@ant-design/icons'
import './progress.less'

class Progress extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <div className='progress'>
                <div className={this.props.progress>1?"circle finishbg":"circle bluebg"}>
                    {this.props.progress > 1 ? <CheckOutlined /> : 1}
                </div>
                <div className="text black-text">公司基本信息</div>
                <div className={this.props.progress >= 2 ? "blue-line line" : "line"}></div>
                <div className={this.props.progress > 1 ? this.props.progress==3?"circle finishbg":'bluebg circle' : 'circle'}>
                    {this.props.progress > 2 ? <CheckOutlined /> : 2}
                </div>
                <div className={this.props.progress > 1 ? 'black-text text' : 'text'}>公司简介</div>
                <div className={this.props.progress == 3 ? "blue-line line" : "line"}></div>
                <div className={this.props.progress > 2 ? 'bluebg circle' : 'circle'}>3</div>
                <div className={this.props.progress > 2 ? 'black-text text' : 'text'}>营业执照与企业logo标志</div>
            </div>
        );
    }
}

export default Progress;