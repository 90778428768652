import React, { Component } from 'react'
import { Button, PageHeader, Avatar, Divider, message, Modal } from 'antd'
import './resume-detail.less'
import {
    StarOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import http from '../../http'
import common from '../../public/base'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf';


export default class resumeDetailRewrite extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            showText: '',
            resumeDetail: {
                name: "",
                sex: 0,
                head_img: "",
                mail: "",
                phone: "",
                birthday: 0,
                start_work: null,
                is_star: 0,
                education: {
                    id: 0,
                    name: "",
                    groups: ""
                },
                experience_edu: [
                    {
                        id: 0,
                        name: "",
                        education: 0,
                        education_name: "",
                        profession: "",
                        description: "",
                        start_time: 0,
                        end_time: 0
                    }
                ],
                experience_work: [{
                    description: "",
                    end_time: 0,
                    id: 0,
                    name: "",
                    position: "",
                    start_time: 0
                }
                ],
                experience_project: [{
                    description: "",
                    end_time: 0,
                    id: 0,
                    name: "",
                    start_time: 0
                }],
                expect_position: [
                    {
                        id: 0,
                        name: "",
                        min_pay: 0,
                        max_pay: 0,
                        province_id: 0,
                        province: "",
                        city_id: 0,
                        city: "",
                        create_time: 0,
                        update_time: 0
                    }
                ],
                tag: [],
                status: 1,
                pdfUrl: '',
                okText: '',
                visiblePay: false,
                payNode: null
            },
            id: null
        }
    }

    getResumeDetail(id) {
        http.getResumeDetail(id).then(res => {
            if (res.result === 1) {
                this.setState({
                    resumeDetail: res.data,
                    id: res.data.id
                })
            }
        })
    }

    componentDidMount() {
        let id = null;
        if (this.props.location.state) {
            id = this.props.location.state.id
            localStorage.setItem('resumeId', id)
        } else {
            id = localStorage.getItem('resumeId')
            // message.success('付费成功，请下载！')
        }
        this.getResumeDetail(id);
    }
    //简历收藏操作
    resumeStar = () => {
        let data = { star: 0 }
        if (this.state.resumeDetail.is_star === 0) {
            data.star = 1;
        }
        http.talentStar(this.state.id, data).then(res => {
            if (res.result === 1) {
                message.success(data.star === 1 ? '收藏成功！' : '取消成功！')
                this.getResumeDetail(this.state.id)
            }
        })
    }

    resumeDownload = () => {
        var test = window.location.href;
        console.log(test)
        const { resumeDetail } = this.state
        console.log(resumeDetail)
        //已付费或已投递该公司可直接下载
        if (resumeDetail.is_pay === 1 || resumeDetail.is_apply === 1) {
            http.resumeDownload(resumeDetail.id).then(res => {
                console.log(111)
                if (res.result === 1) {
                    this.setState({
                        visible: true,
                        showText: resumeDetail.is_pay === 1 ? '您已付费，可以直接下载该简历' : '该人才已向您公司投递简历，您可以直接下载该简历。',
                        pdfUrl: res.data,
                        okText: '下载'
                    })
                }
            })
            return;
        } else {
            //高级简历
            if (resumeDetail.level === 3) {
                http.resumeDownload(resumeDetail.id).then(res => {
                    if (res.result === 1) {
                        this.setState({
                            visible: true,
                            showText: '您已付费，可以直接下载该简历',
                            pdfUrl: res.data,
                            okText: '下载'
                        })
                    }
                }).catch(e => {
                    this.setState({
                        visible: true,
                        showText: '该简历是高级简历，付费三元即可购买，您要前往支付宝扫码支付页付款吗？',
                        okText: '去支付'
                    })
                })
            } else {
                http.resumeDownload(resumeDetail.id).then(res => {
                    console.log(111)
                    if (res.result === 1) {
                        this.setState({
                            visible: true,
                            showText: '每24小时可以免费下载三份简历，如需提升上限，请联系微信客服，您要下载该简历吗？',
                            pdfUrl: res.data,
                            okText: '下载'
                        })
                    }
                }).catch(e => {
                    this.setState({
                        visible: true,
                        showText: '每24小时可以免费下载三份简历，您已用完三次，您需要付费三元下载该简历吗？',
                        okText: '去支付'
                    })
                })
            }
        }
    }
    //确认下载
    sureDownload = () => {
        this.setState({
            visible: false
        })
        const { resumeDetail } = this.state
        if (this.state.pdfUrl != '') {
            try {
                let a = document.createElement('a');
                a.setAttribute('href', this.state.pdfUrl);
                a.setAttribute('download', this.state.resumeDetail.name + "个人简历.pdf");
                a.click();
            } catch (e) {
                message.error('简历附件下载异常！')
            }
        } else {
            let downloadPdf = document.getElementById('downloadPdf');
            let pdfNode = downloadPdf.cloneNode(true)
            console.log(pdfNode)
            document.getElementById('talent-detail-l').appendChild(pdfNode)
            document.getElementsByClassName('phone')[1].innerHTML = resumeDetail.phone
            document.getElementsByClassName('email')[1].innerHTML = resumeDetail.mail
            html2canvas(document.getElementById("talent-detail-l"), {
                height: document.getElementById("downloadPdf").offsetHeight,
                background: "#fff",
                useCORS: true,
                logging: false,
                allowTaint: true,
                onrendered: function (canvas) {
                }
            }).then(function (canvas) {
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = contentWidth / 592.28 * 841.89
                let position = 20
                let imgWidth = 595.28
                let imgHeight = 592.28 / contentWidth * contentHeight
                let pageData = canvas.toDataURL('image/jpeg', 1.0).replace("image/png", "image/octet-stream");
                let pdf = new jsPDF('', 'pt', 'a4')
                if (contentHeight < pageHeight) {
                    pdf.addImage(pageData, 'JPEG', 0, 20, imgWidth, imgHeight);
                } else {
                    pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
                    while (contentHeight > 0) {
                        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
                        contentHeight -= pageHeight;
                        position -= 841.89;
                        //避免添加空白页
                        if (contentHeight > 0) {
                            pdf.addPage();
                        }
                    }
                }
                pdf.save(resumeDetail.name + '的简历' + '.pdf');
            })
        }
    }

    //付费下载
    goPay = () => {
        http.resumeDownloadPay(this.state.resumeDetail.id, { return_url: `${window.location.href}?id=${this.state.resumeDetail.id}` }).then((res => {
            if (res.result === 1) {
                this.setState({

                })
                const newWindow = window.open("", "_self");
                newWindow.document.write(res.data.url.replaceAll('\"', '"'));
                newWindow.focus();
            }
        }))
    }


    render() {
        const { resumeDetail } = this.state
        return (
            <div className="resumeCenter">
                {/* <div className="top-body">
                    <span onClick={() => { this.props.history.go(-1) }}>人才管理</span><span className="italic-line">/</span><span className='theme-color'>职位详情</span>
                </div> */}
                <div className="site-page-header-ghost-wrapper">
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title={resumeDetail.name + "的简历"}
                        extra={[
                            <Button type={resumeDetail.is_star === 0 ? 'default' : 'primary'} key="0" onClick={this.resumeStar} icon={<StarOutlined />}>{resumeDetail.is_star === 0 ? '收藏简历' : '取消收藏'}</Button>,
                            <Button key="1" type="primary" onClick={this.resumeDownload} icon={<DownloadOutlined />}>下载简历</Button>,
                        ]}>
                    </PageHeader>
                    <div id='downloadPdf' className="test">
                        <div className="RDcenter">
                            <div className="header">
                                <div className="avatar" >
                                    <Avatar size={96} src={resumeDetail.head_img} />
                                </div>
                                <div className="detail" >
                                    <div className="characterName">
                                        {this.state.resumeDetail.name}
                                    </div>
                                    <div className="characterCommon">
                                        <div className="basicBox">
                                            <img className="image" src={require('../../assets/resume-icon-1.png').default} alt="" />
                                            {common.filterBirth(this.state.resumeDetail.start_work)}年工作经验
                                                    <Divider type="vertical" />
                                            <img className="image" src={require('../../assets/resume-icon-2.png').default} alt="" />
                                            {this.state.resumeDetail.education.name}
                                            <Divider type="vertical" />
                                            <img className="image" src={require('../../assets/resume-icon-3.png').default} alt="" />
                                            {["离职-随时到岗", "在职-暂不考虑", "在职-考虑机会", "离职-月内到岗"][resumeDetail.status - 1]}

                                        </div>
                                        <div className="basicBox">
                                            <img className="image" src={require('../../assets/resume-icon-4.png').default} alt="" />
                                            {resumeDetail.birthday ? common.dateFormat(resumeDetail.birthday) : '暂无'}
                                            <Divider type="vertical" />
                                            <img className="image" src={require('../../assets/resume-icon-5.png').default} alt="" />
                                            <span className='phone'>{resumeDetail.phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3")}</span>
                                            <Divider type="vertical" />
                                            {/* (\\d{3})\\d{4}(\\d{4}) */}
                                            <img className="image" src={require('../../assets/resume-icon-6.png').default} alt="" />
                                            <span className='email'>{resumeDetail.mail.replace(/(\w?)(\w+)(\w)(@\w+\.[a-z]+(\.[a-z]+)?)/, "$1****$3$4")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider dashed />
                            <div className="detailItem">
                                <div className="detailHeader">
                                    <div className="blueLine"></div>
                                    <div className="blank"></div>
                                    <p className="characterItem">期望职位</p>
                                </div>
                                <div className="detailBox">
                                    {
                                        this.state.resumeDetail.expect_position.map((expect_position, index) => {
                                            return (
                                                <div key={expect_position.id}>
                                                    <img className="image" src={require('../../assets/resume-icon-7.png').default} alt="" />
                                                    {expect_position.name}
                                                    <Divider type="vertical" />
                                                    <img className="image" src={require('../../assets/resume-icon-4.png').default} alt="" />
                                                    {
                                                        this.state.resumeDetail.expect_position.length > 0 ?
                                                            common.dateFormat(resumeDetail.expect_position[0].create_time)
                                                            :
                                                            null
                                                    }
                                                    <Divider type="vertical" />
                                                    <img className="image" src={require('../../assets/resume-icon-8.png').default} alt="" />
                                                        ￥{expect_position.min_pay}-￥{expect_position.max_pay}
                                                    <Divider type="vertical" />
                                                    <img className="image" src={require('../../assets/resume-icon-9.png').default} alt="" />
                                                    {expect_position.province}-{expect_position.city}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Divider dashed />
                            <div>
                                <div className="detailHeader">
                                    <div className="blueLine"></div>
                                    <div className="blank"></div>
                                    <h3 className="characterItem">工作经历</h3>
                                </div>
                                <div className="detailBox">
                                    {
                                        resumeDetail.experience_work.reverse().map((e, index) => {
                                            return (
                                                <div className="insideBox" key={e.id}>
                                                    <div className="itemWithTime">
                                                        <div className="itemWithFontWeight">
                                                            {e.name}
                                                        </div>
                                                        <div className="characterTime">
                                                            {common.dateFormat(e.start_time)}
                                                                    -
                                                                    {common.dateFormat(e.end_time)}
                                                        </div>
                                                    </div>
                                                    <div className="itemWithFontWeight">{e.position}</div>
                                                    <div className="item">{e.description}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Divider dashed />
                            <div>
                                <div className="detailHeader">
                                    <div className="blueLine"></div>
                                    <div className="blank"></div>
                                    <h3 className="characterItem">项目经历</h3>
                                </div>
                                <div className="detailBox">
                                    {
                                        resumeDetail.experience_project.reverse().map((e, index) => {
                                            return (
                                                <div className="insideBox" key={e.id}>
                                                    <div className="itemWithTime">
                                                        <div className="itemWithFontWeight">
                                                            {e.name}
                                                        </div>
                                                        <div className="characterTime">
                                                            {common.dateFormat(e.start_time)}
                                                                    -
                                                                    {common.dateFormat(e.end_time)}
                                                        </div>
                                                    </div>
                                                    <div className="itemWithFontWeight">{e.position}</div>
                                                    <div className="item">{e.description}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Divider dashed />
                            <div>
                                <div className="detailHeader">
                                    <div className="blueLine"></div>
                                    <div className="blank"></div>
                                    <h3 className="characterItem">教育经历</h3>
                                </div>
                                <div className="detailBox">
                                    {
                                        resumeDetail.experience_edu.reverse().map((e, index) => {
                                            return (
                                                <div className="insideBox" key={e.id}>
                                                    <div className="itemWithTime">
                                                        <div className="itemWithFontWeight">
                                                            {e.name}
                                                        </div>
                                                        <div className="characterTime">
                                                            {common.dateFormat(e.start_time)}
                                                                    -
                                                                    {common.dateFormat(e.end_time)}
                                                        </div>
                                                    </div>
                                                    <div className="itemWithFontWeight">
                                                        {e.profession}
                                                        <Divider type="vertical" />
                                                        {e.education_name.replace("|education", "")}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Divider dashed />
                            <div>
                                <div className="detailHeader">
                                    <div className="blueLine"></div>
                                    <div className="blank"></div>
                                    <h3 className="characterItem">擅长专业</h3>
                                </div>
                                <div className="skillBox">
                                    {
                                        resumeDetail.tag.map((e, index) => {
                                            if (e.groups.search("profession") != -1) {
                                                return (<div className="skill" key={e.name}>{e.name}</div>)
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <Divider dashed />
                            <div>
                                <div className="detailHeader">
                                    <div className="blueLine"></div>
                                    <div className="blank"></div>
                                    <h3 className="characterItem">专业软件</h3>
                                </div>
                                <div className="skillBox">
                                    {
                                        resumeDetail.tag.map((e, index) => {
                                            if (e.groups.search("software") != -1) {
                                                return (<div className="skill" key={e.name}>{e.name}</div>)
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <Divider dashed />
                            <div>
                                <div className="detailHeader">
                                    <div className="blueLine"></div>
                                    <div className="blank"></div>
                                    <h3 className="characterItem">熟练技能</h3>
                                </div>
                                <div className="skillBox">
                                    {
                                        resumeDetail.tag.map((e, index) => {
                                            if (e.groups.search("skill") != -1) {
                                                return (<div className="skill" key={e.name}>{e.name}</div>)
                                            }
                                        })
                                    }
                                </div>
                                <Divider dashed />
                            </div>
                            <div>
                                <div className="detailHeader">
                                    <div className="blueLine"></div>
                                    <div className="blank"></div>
                                    <h3 className="characterItem">擅长应用</h3>
                                </div>
                                <div className="skillBox">
                                    {
                                        resumeDetail.tag.map((e, index) => {
                                            if (e.groups.search("apply") != -1) {
                                                return (<div className="skill" key={e.name}>{e.name}</div>)
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <Divider dashed />
                            <div>
                                <div className="detailHeader">
                                    <div className="blueLine"></div>
                                    <div className="blank"></div>
                                    <h3 className="characterItem">擅长品牌</h3>
                                </div>
                                <div className="skillBox">
                                    {
                                        resumeDetail.tag.map((e, index) => {
                                            if (e.groups.search("brand") != -1) {
                                                return (<div className="skill" key={e.name}>{e.name}</div>)
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            <Divider dashed />


                        </div>
                    </div>
                </div>

                <div id="downloadPdfImg"
                    style={{ 'width': '800px', 'position': 'absolute', 'right': '0', 'top': '0', 'height': '0px', 'overflowY': 'auto' }}>

                    <div id="talent-detail-l" style={{ 'width': '800px', 'zIndex': '0' }}>
                        <div style={{ 'textAlign': 'right' }}>
                            <img src={require('../../assets/resume-logo.png').default} style={{ 'height': '35px' }} />
                        </div>
                    </div>
                </div>
                <Modal title='下载提示'
                    okText={this.state.okText}
                    visible={this.state.visible}
                    onCancel={() => { this.setState({ visible: false }) }}
                    onOk={this.state.okText === '下载' ? this.sureDownload : this.goPay}>
                    <p>{this.state.showText}</p>
                </Modal>
                <Modal width={700} title='请支付'
                    visible={this.state.visiblePay}>
                    {/* <div id='pay-page'></div> */}
                    {/* {this.state.payNode?<div dangerouslySetInnerHTML={{__html:``}}></div>:null} */}
                    <iframe
                        title="resg"
                        srcDoc={this.state.payNode}
                        style={{ width: '100%', border: '0px', height: '1100px' }}
                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                        scrolling="auto"
                    />
                </Modal>

            </div>
        )
    }
}
