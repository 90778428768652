import { React, Component } from 'react'
import { Button } from 'antd'

class Detail extends Component {
    state = {}
    render() {
        return (
            <div>
                <p>this is detail ！</p>

                <Button href='/'> 返回主页 </Button>
                <Button onClick={()=>{this.props.history.push('/')}}> 返回主页 </Button>
            </div>
        );
    }
}

export default Detail;