import React, { Component } from 'react';
import './job-detail.less'
import { Button, message, Dropdown, Menu, Spin } from "antd";
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import http from '../../http'

const NavModule = (props) => {
    return <div className="nav-title">
        <div className="line"></div>
        <div className="text">{props.text}</div>
    </div>
}

class JobDetailPage extends Component {
    state = {
        jobDetailData: {},
        loadingFlag: true
    }
    componentDidMount() {
        console.log(this.props.location.state)
        let id = this.props.location.state.id
        http.getJobDetail(id).then(res => {
            if (res.result === 1) {
                this.setState({
                    jobDetailData: res.data,
                    loadingFlag: false
                })
            }
        })
    }
    //下线职位
    outlineJob() {
        let data = {}
        let { jobDetailData } = this.state
        if (jobDetailData.status === 2) {
            data.status = 1
        } else if (jobDetailData.status === 1 || jobDetailData.status === 3) {
            data.status = 2
        }
        http.editJobStatus(jobDetailData.id, data).then(res => {
            message.success(jobDetailData.status === 2 ? '上线成功！' : '下线成功！')
            let jobData = jobDetailData
            jobData.status = data.status
            this.setState({
                jobDetailData: jobData
            })
        })
    }
    //删除职位
    delJob() {
        http.editJobStatus(this.state.jobDetailData.id, { status: 0 }).then(res => {
            message.success('删除成功！')
            this.props.history.go(-1)
        })
    }

    menuClick = (item) => {
        if (item.key == 1) {
            console.log(item)
            http.editJobStatus(this.state.jobDetailData.id).then(res => {
                message.success('刷新成功！')
            })
        } else {
            let data = {}
            if (this.state.jobDetailData.top === 0) {
                data.top = 1
            } else {
                data.top = 0
            }

            http.editJobStatus(this.state.jobDetailData.id, data).then(res => {
                message.success(this.state.jobDetailData.top === 0 ? '置顶成功！' : '取消成功!')
                let jobData = this.state.jobDetailData
                jobData.top = data.top
                this.setState({
                    jobDetailData: jobData
                })
            })
        }
    }

    render() {
        const { jobDetailData } = this.state

        const menu = (
            <Menu onClick={this.menuClick}>
                <Menu.Item key='1'>
                    刷新职位
                </Menu.Item>
                <Menu.Item key='2'>
                    {jobDetailData.top === 0 ? '职位置顶' : '取消置顶'}
                </Menu.Item>
            </Menu>
        );
        return <div className='job-detail'>
            <div className="top-body">
                <span onClick={() => { this.props.history.go(-1) }}>职位管理</span><span className="italic-line">/</span><span className='theme-color'>职位详情</span>
            </div>

            {
                this.state.loadingFlag ?
                    <div className="content-body"><Spin tip="加载中..."></Spin></div> :
                    <div className="content-body">
                        <div className="top-btn">
                            <p className='title'><span onClick={()=>this.props.history.go(-1)}><ArrowLeftOutlined /></span><span className='name'>{jobDetailData.name}</span><span className='status'>{jobDetailData.status == 2 ? '停止招聘' : jobDetailData.status == 0 ? '已删除' : '招聘中'}</span></p>
                            <div className="btn-box">
                                <Button
                                    onClick={() => { this.props.history.push({ pathname: '/jobManege/postJob', state: { data: jobDetailData, edit: true } }) }}
                                    type='primary'>编辑职位</Button>
                                <Button type='default' onClick={this.outlineJob.bind(this)}>{jobDetailData.status === 2 ? '上线职位' : '下线职位'}</Button>
                                <Button type='primary' onClick={this.delJob.bind(this)} danger ghost>删除职位</Button>
                                <Dropdown overlay={menu} placement="bottomLeft" arrow>
                                    <Button type='default'>……</Button>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="base-info">
                            <div className="base-info-box">
                                <p className="info-top">薪资待遇</p>
                                <p className="info-bottom">{jobDetailData.salary.name}</p>
                            </div>
                            <div className="base-info-box">
                                <p className="info-top">经验要求</p>
                                <p className="info-bottom">{jobDetailData.work_years.name}</p>
                            </div>
                            <div className="base-info-box">
                                <p className="info-top">职位性质</p>
                                <p className="info-bottom">{jobDetailData.nature == 0 ? '全职' : jobDetailData.nature == 1 ? '兼职' : '实习'}</p>
                            </div>
                            <div className="base-info-box">
                                <p className="info-top">学历要求</p>
                                <p className="info-bottom">{jobDetailData.education.name}</p>
                            </div>
                            <div className="base-info-box">
                                <p className="info-top">职位福利</p>
                                <p className="info-bottom">{jobDetailData.welfare.map(e => {
                                    return <span key={e.id} className='welfare'>{e.name}</span>
                                })}</p>
                            </div>
                        </div>
                        <NavModule text="职位描述"></NavModule>
                        <p className="text-msg">{jobDetailData.description}</p>
                        <NavModule text="任职要求"></NavModule>
                        <p className="text-msg">{jobDetailData.require}</p>
                        <NavModule text="工作地址"></NavModule>
                        <p className="text-msg">{jobDetailData.address.province}{jobDetailData.address.city}{jobDetailData.address.region}{jobDetailData.address.address}</p>
                    </div>}
        </div>;
    }
}

export default JobDetailPage;