import { React, Component } from 'react'
import { Link, } from "react-router-dom";
import { ConfigProvider, Layout, Menu, Dropdown } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN';
import './company-index.less'
import {
    InfoCircleOutlined,
    TeamOutlined,
    LogoutOutlined,
    WechatOutlined,
    DownOutlined
} from '@ant-design/icons';
import http from '../http'


const { Header, Sider, Content } = Layout;

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            select: [this.props.location.pathname],
            userInfo: {}
        };
    }

    componentDidMount() {
        let that = this;
        let data = JSON.parse(localStorage.getItem('userInfo'));
        this.setState({
            userInfo: data
        }, () => {
        })


        http.getMapInfo({ id: 1 }).then(res => {
            localStorage.setItem('map', JSON.stringify(res.data.sub))
        })
        http.getWorkYear().then(res => {
            localStorage.setItem('work_year', JSON.stringify(res.data))
        })
        http.getEducation().then(res => {
            localStorage.setItem('eduction', JSON.stringify(res.data))
        })
        http.getSalary().then(res => {
            localStorage.setItem('salary', JSON.stringify(res.data))
        })
    }


    menuClick(e) {
        setTimeout(() => {
            this.setState({ select: e.key })
        }, 10);
    }


    // 下拉菜单点击事件
    navMenuClick(e) {
        if (e.key === '3') {
            localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
        }
    }

    //顶部下拉菜单菜单
    TopWidget() {
        const menu = (
            <Menu onClick={this.navMenuClick.bind(this)} className='drop-down'>
                <Menu.Item key="0">
                    <div className="row-right-user-info">
                        <div className="znc-logo"  style={{'backgroundImage':`url(${this.state.userInfo.logo})`}}></div>
                        <div className="right">
                            <p className='phone'>{this.state.userInfo.phone}</p>
                            <p>{this.state.userInfo.name}</p>
                        </div>
                    </div>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="1" icon={<InfoCircleOutlined />}>
                    <Link to={{ pathname: '/accountIndex', state: { current: '1' } }}> 企业信息</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<TeamOutlined />}>
                    <Link to={{ pathname: '/accountIndex', state: { current: '2' } }}> 账户管理</Link>
                </Menu.Item>
                <Menu.Item className='out-login' key="3" icon={<LogoutOutlined />}>
                    <Link to="/login">退出登录</Link>
                </Menu.Item>
            </Menu>
        );
        return <Header className="site-layout-header" style={{ padding: 0, 'backgroundColor': '#fff' }}>
            <div className="left-header">
                <div className="logo" >
                    {/* <Link to="/indexPage"> */}
                    <img src={require('./../assets/logo.png').default} alt="" className="znc-logo" />
                    {/* </Link> */}
                </div>
                <div className='page-name'>{this.props.routes.title}</div>
            </div>
            <Dropdown overlay={menu}>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <div className='right-user-info'><div className="right-znc-logo" style={{'backgroundImage':`url(${this.state.userInfo.logo})`}}></div>
                        <p style={{'marginRight':'8px'}}>{this.state.userInfo.name ?? '暂无信息'}</p>  <DownOutlined />
                    </div>
                </a>
            </Dropdown>
        </Header>
    }

    componentWillReceiveProps(nextProps) {
        // console.log(this.state.select+'######') 
        if (nextProps.location.pathname != this.props.location.pathname) {
            document.getElementsByClassName('site-layout')[0].scrollTop = 0
            // window.leftMenu.forceUpdate(); //当路由不同时，强制更新左边栏组件
        }
    }

    render() {
        return (
            <div className='company-index'>
                <Layout>
                    <Header>
                        <div className='right-nav'>
                            {this.TopWidget()}
                        </div>
                    </Header>
                    <Layout className="site-layout">
                        <LiftMemu location={this.props.location}></LiftMemu>
                        <Content className="site-layout-background">
                            {/* 配置中文 */}
                            <ConfigProvider locale={zhCN}>
                                {/* //路由 */}
                                {this.props.children}
                            </ConfigProvider>
                        </Content>
                    </Layout>
                </Layout>

            </div>
        );
    }
}

class LiftMemu extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Sider trigger={null} collapsible className='left-aside' collapsed={false}>
                <Menu theme="light" mode="inline" selectedKeys={[this.props.location.pathname]} style={{ 'backgroundColor': '#19316B' }}>
                    <Menu.Item key="/indexPage" >
                        <Link to="/indexPage"><img src={require('./../assets/home.png').default} alt="" />
                        首页</Link>
                    </Menu.Item>
                    <Menu.Item key="/jobManege">
                        <Link to="/jobManege"><img src={require('./../assets/job.png').default} alt="" />
                        职位管理</Link>
                    </Menu.Item>
                    <Menu.Item key="/talentManage" >
                        <Link to="/talentManage"><img src={require('./../assets/pople.png').default} alt="" />
                        人才管理</Link>
                    </Menu.Item>
                    <Menu.Item key="/talentCollection">
                        <Link to="/talentCollection"><img src={require('./../assets/like.png').default} alt="" />
                        人才收藏</Link>
                    </Menu.Item>
                </Menu>
                <div>
                    <div className="nav-bottom">
                        <div className="mb">
                            <img src={require('../assets/wx.png').default} alt="" />
                        </div>
                        <img className='logo-white' src={require('../assets/znc-logo-white.png').default} alt="" />
                        <div className="right">
                            <p>杨老师 <WechatOutlined /></p>
                            <p>您的专属招聘顾问</p>
                        </div>
                    </div>
                </div>
            </Sider>)
    }
}


export default Home;

