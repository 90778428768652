import React, { Component, useEffect, useState } from 'react';
import AccountBar from './account-bar';
import Progress from './progress';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Layout, Form, Input, Button, Select, Upload, message, Spin, Tag, Modal } from "antd";
import './company-base-info.less';
import http from '../../http';
import common from './../../public/base';


const { Content } = Layout;
const { Option } = Select;

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const validateMessages = {
    required: '${label}是必填选项!',
    types: {
        email: '请输入${label}!',
    },
};

const ThreeHtml = props => {
    const [form3] = Form.useForm();
    const { companyInfo } = props;
    const [loading, setLoading] = useState(false)
    const [loadingLogo, setLoadingLogo] = useState(false)
    const [fileList, setFileList] = useState([])
    const [licenseImg, setLicenseImg] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    useEffect(() => {
        form3.setFieldsValue({
            logo: companyInfo.logo,
            license: companyInfo.license,
        });
        setLicenseImg(companyInfo.license);
        setLogoUrl(companyInfo.logo);

    }, [companyInfo])

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('请上传jpeg、png格式图片！');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小不超过2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const handleChangebBusiness = info => {
        console.log(info)
        if (info.file.status === 'uploading') {
            setLoading(true)
        }
        if (info.file.status === 'done') {
            common.getBase64(info.file.originFileObj, imageUrl => {
                setLoading(false)
                setLicenseImg(info.file.response.data)
            }
            );
        }
        setFileList([...info.fileList]);
        console.log(fileList)
    };


    const handleChangeLogo = info => {
        if (info.file.status === 'uploading') {
            setLoadingLogo(true)
        }
        if (info.file.status === 'done') {
            common.getBase64(info.file.originFileObj, (imageUrl) => {
                setLoadingLogo(false)
                setLogoUrl(info.file.response.data)
            }
            );
        }
        setFileList([...info.fileList])
    }

    const putFinish = values => {
        props.finishPut(values)

    }
    const UploadButton = (prop) => {
        return (<div>
            {prop.loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>);
    };
    return (
        <Form form={form3} {...layout} name="nest-messages" onFinish={putFinish} validateMessages={validateMessages}>

            <Form.Item name='license' label="营业执照" rules={[{ required: true }]}>
                <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={http.baseUrl + "/common/file/upload/img"}
                    beforeUpload={beforeUpload}
                    onChange={handleChangebBusiness}
                    data={file => ({// file 是当前正在上传的图片
                        file_name: file.size + file.name.replace(/\s/g, '')
                    })}
                    fileList={fileList}
                    maxCount={1}
                    headers={{ 'id': 0, }}
                >
                    {licenseImg ? <img src={licenseImg} alt="avatar" style={{ width: '100%' }} /> : <UploadButton loading={loading} />}
                </Upload>
            </Form.Item>
            <Form.Item name='logo' label="公司logo" rules={[{ required: true }]} style={{ 'position': 'relative' }}>
                <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={http.baseUrl + "/common/file/upload/img"}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeLogo}
                    data={file => ({// file 是当前正在上传的图片
                        file_name: file.size + file.name.replace(/\s/g, '')
                    })}
                    fileList={fileList}
                    maxCount={1}
                    headers={{ 'id': 0, }}
                >
                    {logoUrl ? <img src={logoUrl} alt="avatar" style={{ width: '100%' }} /> : < UploadButton loading={loadingLogo} />}
                </Upload>
            </Form.Item>

            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                <span style={{ 'color': '#FF4D4F' }}>*</span>营业执照上传完成以后，机器人人才网会对营业执照信息真实性进行审核，请谨慎上传，创建后不可更改。
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                <Button onClick={() => { props.onBack() }}>
                    回到上一步
                    </Button>
                <Button type="primary" htmlType="submit" style={{ 'marginLeft': '8px' }}>
                    提交审核
                    </Button>
            </Form.Item>
        </Form>
    );
}
//弹窗选择职位福利
class ShowindustryWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectIndudtry: [],
            allIndustryList: []
        }
    }
    // handleSendPMsg = ()=>{
    //   this.props.getMsg(this.state.val)
    // }
    componentDidMount() {
        console.log(this.props, "+++++++++++++++++")
        let data = [];
        this.props.allIndustryList.map((e) => {
            e.select = false;
            if (this.props.selectIndudtry) {
                this.props.selectIndudtry.map((item) => {
                    if (e.name === item.name) {
                        e.select = true;
                    }
                })
            }
            data.push(e)
        })
        this.setState({
            allIndustryList: data,
            selectIndudtry: this.props.selectWelfare
        })
    }

    handleSendPMsg(index) {
        // console.log(this.state.allWelfareList)
        let data = this.state.allIndustryList;
        data[index].select = !data[index].select
        let selectData = []
        data.forEach(element => {
            if (element.select) {
                selectData.push(element)
            }
        });
        this.setState({
            allIndustryList: data,
            selectIndudtry: selectData
        })
        this.props.onSelectIndudtry(selectData)
    }
    render() {
        const selectStyle = { 'marginRight': '8px', 'marginBottom': '8px', 'borderColor': '#3188F3', 'color': '#3188F3' };
        return this.props.allIndustryList.map((e, index) => {
            return <li onClick={this.handleSendPMsg.bind(this, index)} className='welfare-li' style={e.select ? selectStyle : { 'marginRight': '8px', 'marginBottom': '8px' }} key={e.id}>{e.name}</li>
        })
    }
}
//第一步
const FirstForm = props => {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const { data } = props
    const { companyInfo } = data
    const [progress, setProgress] = useState(1);
    const [cityInfo, setCityInfo] = useState([]);
    const [regionInfo, setRegionInfo] = useState([]);
    const [firstData, setFirstData] = useState({});
    const [secondData, setSecondData] = useState({});
    const [selectIndustry, setSelectIndustry] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false)
    let industryList = [];

    useEffect(() => {
        console.log(props)
        form1.setFieldsValue({
            name: companyInfo.name,
            mail: companyInfo.mail,
            scale: companyInfo.scale.name != '' ? companyInfo.scale.id : null,
            types: companyInfo.types ? companyInfo.types : null,
        });
        form2.setFieldsValue({
            description: companyInfo.description,
            province: companyInfo.address.province ? companyInfo.address.province_id : null,
            city: companyInfo.address.city ? companyInfo.address.city_id : null,
            region: companyInfo.address.region ? companyInfo.address.region_id : null,
            address_str: companyInfo.address ? companyInfo.address.address : '',
            url: companyInfo.url,
        });
        setCityInfo(data.cityInfo);
        setRegionInfo(data.regionInfo);
        setSelectIndustry(companyInfo.industry)
    }, [data])
    const firstFinish = values => {
        if (selectIndustry.length == 0) {
            message.error('所属行业是必填项！');
            return;
        }
        let industry = []
        selectIndustry.map(e => {
            industry.push(e.id)
        })
        let data = values; 
        data.industry = industry.join(',')
        setFirstData(data);
        console.log(data)
        setProgress(2);
    }
    const secondFinish = values => {
        setSecondData(values);
        setProgress(3);
    }
    const handleProvinceChange = value => {
        http.getMapInfo({ id: value }).then(res => {
            setCityInfo(res.data.sub);
        })

    }
    const handleCityChange = value => {
        http.getMapInfo({ id: value }).then(res => {
            setRegionInfo(res.data.sub)
        })

    }
    const FirstHtml = () => {
        //删除行业
        const closeTag = e => {
            const tags = selectIndustry.filter(tag => tag !== e);
            setSelectIndustry(tags)
        }
        //添加行业
        const editIndustry = () => {
            setIsModalVisible(true)
        }
        return <Form form={form1} {...layout} name="nest-messages" onFinish={firstFinish} validateMessages={validateMessages}>
            <Form.Item name='name' label="公司名称" rules={[{ required: true }]}>
                <Input placeholder='请填写公司营业执照上的完整公司名称' />
            </Form.Item>
            <Form.Item name='mail' label="常用邮箱" rules={[{ required: true, type: 'email', }]}>
                <Input placeholder='请输入接收简历的邮箱' />
            </Form.Item>
            <Form.Item
                name="scale"
                label="公司规模"
                rules={[{ required: true, message: '请选择公司规模' }]}>
                <Select placeholder="请选择">
                    {data.companyScales.map((e, index) => {
                        return <Option key={e.id} value={e.id}>{e.name}</Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                name="types"
                label="公司类型"
                rules={[{ required: true, message: '请选择公司类型' }]}>
                <Select placeholder="请选择">
                    {data.companyTypes.map((e, index) => {
                        return <Option key={e.id} value={e.id}>{e.name}</Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item className='job-type' label="所属行业">
                {selectIndustry.map((e, index) => {
                    return <Tag className='disabled-tag' key={e} onClose={() => closeTag(e)} key={e.id}>{e.name}</Tag>
                })}
                {<Button type="dashed" onClick={editIndustry}>+ 添加行业</Button>}
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                <Button type="primary" htmlType="submit">
                    下一步，公司简介
                </Button>
            </Form.Item>
        </Form>
    }
    const SecondHtml = () => {
        return <Form form={form2} {...layout} name="nest-messages" onFinish={secondFinish} validateMessages={validateMessages}>
            <Form.Item name='description' label="企业简介" rules={[{ required: true }]}>
                <Input.TextArea className='text-area' placeholder='请输入公司简介，最多可输入1000字' showCount maxLength={1000} />
            </Form.Item>
            <Form.Item className='address' label="公司地址" >
                <Form.Item name='province' style={{ display: 'inline-block', width: 'calc(33.3% - 24px)' }} rules={[{ required: true, message: '省是必填选项！' }]}>
                    <Select placeholder='请选择省' onChange={handleProvinceChange}>
                        {data.provinceInfo.map(province => (
                            <Option key={province.id} value={province.id}>{province.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <span style={{ display: 'inline-block', width: '24px' }}></span>
                <Form.Item name='city' style={{ display: 'inline-block', width: 'calc(33.3% - 24px)' }} rules={[{ required: true, message: '市是必填选项！' }]}>
                    <Select placeholder='请选择市' onChange={handleCityChange}>
                        {cityInfo.map(e => (
                            <Option key={e.id} value={e.id}>{e.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <span style={{ display: 'inline-block', width: '24px' }}></span>
                <Form.Item name='region' style={{ display: 'inline-block', width: 'calc(33.3%)' }} rules={[{ required: true, message: '区是必填选项！' }]}>
                    <Select placeholder='请选择区'>
                        {regionInfo.map(e => (
                            <Option key={e.id} value={e.id}>{e.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form.Item>

            <Form.Item name='address_str' rules={[{ required: true, message: "详细地址是必填选项！" }]} wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                <Input placeholder='请输入详细地址' />
            </Form.Item>

            <Form.Item name='url' label='公司网址'>
                <Input placeholder='请输入公司网址' />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
                <Button onClick={() => setProgress(1)}>
                    回到上一步
                </Button>
                <Button type="primary" htmlType="submit" style={{ 'marginLeft': '8px' }}>
                    下一步，公司简介
                </Button>
            </Form.Item>
        </Form>
    }

    //确认所选行业
    const handleOk = () => {
        setSelectIndustry(industryList)
        setIsModalVisible(false)
    }
    //选择行业
    const onSelectIndudtry = (res) => {
        console.log(res)
        industryList = res;
    }
    //提交审核
    const onFinishPut = values => {
        let data = Object.assign(
            firstData,
            secondData,
            {
                logo: values.logo.file ? values.logo.file.response.data : values.logo,
                license: values.license.file ? values.license.file.response.data : values.license
            });
        if (companyInfo.status === 4) {
            data.status = 1;
        }
        if (selectIndustry.length == 0) {
            message.error('所属行业是必填项！');
            return;
        }
        console.log(data)
        http.reviseCompanyInfo(data).then(res => {
            if (res.result == 1) {
                message.success("提交成功！")
                props.onPreview();
                // this.props.history.push('/')
            }
        })
    }
    return (<div>
        <Progress progress={progress} />
        <Content className='form-box'>
            {progress == 1 ? <FirstHtml></FirstHtml>
                : progress == 2 ? <SecondHtml></SecondHtml>
                    : <ThreeHtml onBack={() => setProgress(2)} finishPut={onFinishPut} companyInfo={companyInfo}></ThreeHtml>}
        </Content>
        <Modal width={700} className='welfare-box' cancelText='取消' okText='确定' title="职位福利" visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)}>
            <ShowindustryWidget onSelectIndudtry={onSelectIndudtry} allIndustryList={data.industryList} selectIndudtry={companyInfo.industry ? companyInfo.industry : []}></ShowindustryWidget>

        </Modal>
    </div>
    );
}


class CompanyBaseInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            progress: 1,
            companyScales: [],
            companyTypes: [],
            firstData: {},
            secondData: {},
            provinceInfo: [],
            cityInfo: [],
            regionInfo: [],
            companyInfo: {},
            checkStatus: false,  //审核状态，默认是未提交 填写信息，true 已提交信息审核中
            loadingFlag: true,
            aginReviewFlag: false  //重新审核，修改企业信息
        }
    }

    componentDidMount() {
        console.log('baseInfo')
        http.getCompanyInfo().then(res => {
            http.getCompanyTypes().then(type => {
                http.getCompanyScales().then(scale => {
                    http.getMapInfo({ id: 1 }).then(map => {
                        http.getIndustry().then(industry => {
                            this.setState({
                                loadingFlag: false,
                                companyInfo: res.data,
                                checkStatus: res.data.status,
                                companyTypes: type.data,
                                companyScales: scale.data,
                                provinceInfo: map.data.sub,
                                industryList: industry.data
                            })
                        })
                    })
                })
            })
        })
    }

    //
    onPreview = () => {
        http.getCompanyInfo().then(res => {
            localStorage.setItem('userInfo', JSON.stringify(res.data))
            this.setState({
                checkStatus: res.data.status,
                companyInfo: res.data
            })
        })
    }

    BodyWiget = () => {
        const { companyInfo } = this.state
        if (this.state.checkStatus === 4) {
            if (this.state.aginReviewFlag) {
                return (<FirstForm data={{
                    companyScales: this.state.companyScales,
                    companyInfo: this.state.companyInfo,
                    companyTypes: this.state.companyTypes,
                    progress: this.state.progress,
                    provinceInfo: this.state.provinceInfo,
                    cityInfo: this.state.cityInfo,
                    regionInfo: this.state.regionInfo,
                    industryList: this.state.industryList
                }}
                    onPreview={this.onPreview}></FirstForm>)
            } else {
                return <div className="check-box">
                    <div className="box">
                        <img src={require('./../../assets/check-status-fail.png').default} alt="" />
                        <p>您填写的企业信息未通过审核。</p>
                        <Button onClick={() => this.setState({ aginReviewFlag: true })} style={{ 'margin': '0 auto', 'display': 'block', 'marginTop': '24px' }} type='primary'>修改企业信息</Button>
                    </div>
                </div>
            }
        } else if (this.state.checkStatus === 1) {
            if (companyInfo.logo && companyInfo.name && companyInfo.description) {
                return <div className="check-box">
                    <div className="box">
                        <img src={require('./../../assets/check-status.png').default} alt="" />
                        <p >你的信息已提交，我们将在72小时内审核您的信息，请耐心等待。</p>
                    </div>
                </div>
            } else {
                return (<FirstForm data={{
                    companyScales: this.state.companyScales,
                    companyInfo: this.state.companyInfo,
                    companyTypes: this.state.companyTypes,
                    progress: this.state.progress,
                    provinceInfo: this.state.provinceInfo,
                    cityInfo: this.state.cityInfo,
                    regionInfo: this.state.regionInfo,
                    industryList: this.state.industryList
                }}
                    onPreview={this.onPreview}></FirstForm>)
            }
        } else if (this.state.checkStatus === 5||this.state.checkStatus === 0) {
            return <div className="check-box">
                <div className="box">
                    <img style={{ 'width': '200px' }} src={require('./../../assets/check-status-error.png').default} alt="" />
                    <p>你的账号已经被删除或封号，请联系客服（扫描上方二维码或拨打4008616755）以解决账号问题。</p>
                </div>

            </div>
        }
        else if (this.state.checkStatus === 3 || this.state.checkStatus === 2) {
            this.props.history.push("/indexPage");
            return <div></div>
        }
    }

    render() {
        return (
            <div className='body-bg'>
                <AccountBar />
                {this.state.loadingFlag ? <Spin tip="加载中..."></Spin> : <this.BodyWiget></this.BodyWiget>
                }
            </div>
        );
    }
}

export default CompanyBaseInfo;