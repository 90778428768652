import React, { Component } from 'react';
import { Row, Col, Avatar, Button } from "antd";
import './base-job-item-widget.less'
import common from './base'

class JobLiHtml extends React.Component {
    constructor(props) {
        super(props)
    }


    render() {
        const { resumeDetail } = this.props
        const {expect_position} = resumeDetail;
        return <div className='job-item'>
            <Row>
                <Col span='9'>
                    <div className="mesege-top">
                        <Avatar size={40} src={resumeDetail.head_img} />
                        <p className="name">{resumeDetail.name}</p></div>
                    <p className="mesege-center">
                        {resumeDetail.sex === 0 ? '男' : '女'}<span>|</span>
                        {common.filterBirth(resumeDetail.birthday)}岁<span>|</span>
                        {common.filterBirth(resumeDetail.start_work)}年工作经验<span>|</span>
                        {resumeDetail.education.name}<span>|</span>
                        {["离职-随时到岗", "在职-暂不考虑", "在职-考虑机会", "离职-月内到岗"][resumeDetail.job_status-1]}
                    </p>
                    {expect_position.length>0?
                    <p className="mesege-bottom">
                        期望：{expect_position[0].city}<span>|</span>
                        {expect_position[0].name}<span>|</span>
                        ¥{expect_position[0].min_pay}-¥{expect_position[0].max_pay}
                    </p>:
                    <div className="mesege-bottom">
                        期望：暂无
                        
                    </div>}
                </Col>
                <Col span='11'className='mesege-right'>
                    <div className="work">
                        {resumeDetail.experience_work.map((e, index) => {
                            if(index<3){
                                if(index===0){
                                    return <p key={index} className="item">
                                        <img className="icon" src={require('../assets/talentCollection-icon.png').default} alt="" />
                                        <span>{common.dateFormat(e.start_time)} - {common.dateFormat(e.end_time)}</span> {e.name}&nbsp;&nbsp;&nbsp; {e.position}</p>
                                }else{

                                return <p key={index} className="item">
                                <span className="icon"></span>
                                <span>{common.dateFormat(e.start_time)} - {common.dateFormat(e.end_time)}</span> {e.name}&nbsp;&nbsp;&nbsp; {e.position}</p>
                                }
                            }
                        })}
                    </div>
                    <div className="work">
                            {resumeDetail.experience_edu.map((e, index) => {
                                if(index<1){
                                    return <p key={index} className="item"><img className="icon" src={require('../assets/talentCollection-icon.png').default} alt="" /><span>{common.dateFormat(e.start_time)} - {common.dateFormat(e.end_time)}</span> {e.name}&nbsp;&nbsp;&nbsp; {e.profession}</p>
                                }
                            })}
                    </div>
                </Col>
                <Col span='2' offset="1"  style={{textAlign:'right','lineHeight':'109px'}}>
                    <Button onClick={()=>{this.props.jumpJobDetaiil()}} size='large' type='primary'>查看人才</Button>
                </Col>
            </Row>
        </div>;
    }
}

export default JobLiHtml;