import React, { Component } from 'react';
import "antd/dist/antd.css";
import Routers from './router/index'
import companyIndex from './components/company-index'

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { date: new Date(), name: 'ccmaia' };
  }
  render() {
    return (
      <Routers {...this.props}></Routers>
      );
  }
}

export default App;